import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  .box-login {
    display: flex;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 50px;
    width: 70%;
    max-width: 800px;
    border-radius: 10px;

    animation: ${appearFromTop} 1s;

    @media (max-width: 769px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      button {
        width: 100%;
        margin-top: 10px;
      }
    }

    .logo-box {
      width: calc(50% - 60px);
      padding-right: 60px;
      margin-right: 60px;
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 5%;
        height: 90%;
        width: 1px;
        background: #cfcfcf;
      }

      img {
        object-fit: scale-down;
        width: 100%;
      }

      @media (max-width: 769px) {
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 60px;
        width: 100%;

        &:after {
          display: none;
        }

        img {
          height: 50px;
        }
      }
    }

    .form-box {
      width: 50%;

      label {
        width: 100%;
        display: inline-block;
        font-size: 20px;
        color: #999999;
        font-weight: 400;
        margin-bottom: 7px;
      }

      .forgot_pass {
        display: block;
        background: transparent;
        border: 0;
        margin-bottom: 20px;
        font-weight: 400;
        color: #999999;
        width: 100% !important;
        text-align: left;
        font-size: 14px !important;
      }

      button {
        width: 180px;
        line-height: 10px;
        font-size: 17px;
      }

      @media (max-width: 769px) {
        width: 100%;
      }
    }
  }
`;
