import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import { format } from 'date-fns';
import { AiFillPrinter } from 'react-icons/ai';
import { BiFilterAlt, BiSearchAlt } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Form as FilterForm } from '@unform/web';
import Form from './form';

import Input from '../../components/Input';
import Menu from '../../components/Menu';
import Select from '../../components/Select';
import Table from '../../components/Table';
import HistoryModal from '../../components/History';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import { dateIsoToDate } from '../../utils/dateIsoToDate';

import SchedulesList from './SchedulesList';
import SchedulesListFound from './SchedulesListFound';

import {
  BoxCalendar, Container, Content, Schedules
} from './styles';

interface SelectType {
  value: number;
  label: string;
}

interface Filter {
  offices: number[];
  modalities: number[];
  search: string;
  status: string;
  user_id: string;
}

export interface Appointment {
  id: number;
  name: string;
  phone: string;
  email: string;
  date_of_birth?: Date;
  scheduling_date: Date;
  address: string | null;
  user?: any;
  user_id?: any;
  observation?: string;
  time_start: {
    value: string;
    label: string;
  };
  status: string;
  office: {
    name: string;
    id: number;
  };
  modality: {
    name: string;
    id: number;
  };
  vaccines: {
    id: number;
    name: string;
  }[];
  exams: {
    id: number;
    name: string;
  }[];
  office_id: {
    label: string;
    value: {
      id: number;
      working_days: {
        id: number;
        name: string;
        pivot: {
          modality_id: number;
        };
      }[];
    };
  };
  modality_id: {
    label: string;
    value: number;
  };
}

const Calendar: React.FC = () => {
  const customSearchFormRef = useRef<FormHandles>(null);
  const filtersFormRef = useRef<FormHandles>(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthAvailability, setMonthAvailability] = useState<string[]>([]);
  const [schedules, setSchedules] = useState<Appointment[]>([]);
  const [offices, setOffices] = useState<SelectType[]>([]);
  const [modalities] = useState<SelectType[]>([
    { value: 1, label: 'Atendimento na clinica' },
    { value: 2, label: 'Domiciliar' },
    { value: 3, label: 'Drive-Thru' },
  ]);
  const [showForm, setShowForm] = useState(false);
  const [
    currentAppointment,
    setCurrentAppointment,
  ] = useState<Appointment | null>(null);
  const [filters, setFilters] = useState<Filter>({
    offices: [],
    modalities: [],
    search: '',
    status: '',
    user_id: '',
  });

  const [canLoad, setCanLoad] = useState(false);
  const [loadingMore, setLoadingMore] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [availableProfessionals, setAvailableProfessionals] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [showHistory, setShowHistory] = useState<boolean>(false);

  const [schedulesFound, setSchedulesFound] = useState<Appointment[]>([]);
  const [currentPageFound, setCurrentPageFound] = useState(1);
  const [totalPagesFound, setTotalPagesFound] = useState(1);
  const [loadingMoreFound, setLoadingMoreFound] = useState(true);
  const [searchingSchedules, setSearchingSchedules] = useState(false);
  const [searchScheduleText, setSearchScheduleText] = useState<string>("");

  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    api.get('all-professionals').then(response => {
      setAvailableProfessionals(
        response.data.items.map((user: any) => {
          return {
            label: user.name,
            value: user.id,
          };
        }),
      );
    }).catch(err => {
      setAvailableProfessionals([]);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: err.message,
      });
    });
  }, []);

  // Load Offices
  useEffect(() => {
    api.get('user/offices').then(response => {
      setOffices(
        response.data.map((of: any) => {
          return {
            label: of.name,
            value: of.id,
          };
        }),
      );
      setCanLoad(true);
      setFilters({ ...filters });
    }).catch(err => {
      setOffices([]);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: err.message,
      });
    });
  }, []);

  // load schedules
  useEffect(() => {
    if (canLoad) {
      if (currentPage === 1) {
        setLoadingData(true);
      } else {
        setLoadingMore(true);
      }

      // console.log('SELECTED DATE', selectedDate)
      // console.log('SELECTED DATE', format(selectedDate, 'yyyy-MM-dd'))

      api
        .get('schedules', {
          params: {
            scheduling_date: format(selectedDate, 'yyyy-MM-dd'),
            offices: JSON.stringify(filters.offices),
            modalities: JSON.stringify(filters.modalities),
            search: filters.search,
            status: filters.status,
            user_id: filters.user_id,
            page: currentPage
          },
        })
        .then(response => {
          // console.log(response)
          const schedulesData = response.data.items.map((item: Appointment) => {
            return {
              ...item,
              time_start: { label: item.time_start, value: item.time_start },
              scheduling_date: dateIsoToDate(item.scheduling_date),
              date_of_birth: item.date_of_birth
                ? new Date(item.date_of_birth)
                : undefined
            };
          });

          var aux: any;

          if (currentPage > 1) {
            aux = schedules.concat(schedulesData);
          } else {
            aux = schedulesData;
          }

          // console.log("AAA", aux)

          setSchedules(aux);
          setTotalPages(response.data.last_page);
          setLoadingData(false);
          setLoadingMore(false);
        }).catch(err => {
          setLoadingData(false);
          setLoadingMore(false);
        });
    }
  }, [selectedDate, filters, currentPage, refresh]);

  // load schedules found
  useEffect(() => {
    if (canLoad) {
      if (currentPageFound === 1) {
        setLoadingData(true);
      } else {
        setLoadingMoreFound(true);
      }

      api
        .get('schedules', {
          params: {
            search: searchScheduleText,
            page: currentPageFound,
            order_by: JSON.stringify([
              {
                field: 'scheduling_date',
                order: 'desc'
              }
            ])
          },
        })
        .then(response => {
          const schedulesData = response.data.items.map((item: Appointment) => {
            return {
              ...item,
              time_start: { label: item.time_start, value: item.time_start },
              scheduling_date: dateIsoToDate(item.scheduling_date),
              date_of_birth: item.date_of_birth
                ? new Date(item.date_of_birth)
                : undefined,
            };
          });

          var aux: any;

          if (currentPageFound > 1) {
            aux = schedulesFound.concat(schedulesData);
          } else {
            aux = schedulesData;
          }

          setSchedulesFound(aux);
          setTotalPagesFound(response.data.last_page);
          setLoadingData(false);
          setLoadingMoreFound(false);
        }).catch(err => {
          setLoadingData(false);
          setLoadingMoreFound(false);
        });
    }
  }, [currentPageFound, searchScheduleText]);

  // load available days on month
  useEffect(() => {
    if (canLoad) {
      api
        .get('scheduled-days', {
          params: {
            offices: JSON.stringify(filters.offices),
            modalities: JSON.stringify(filters.modalities),
            search: filters.search,
            status: filters.status,
            user_id: filters.user_id,
            month: currentMonth.getMonth() + 1,
            year: currentMonth.getFullYear(),
          },
        })
        .then(response => {
          setMonthAvailability([...response.data.array_days]);
        }).catch(err => {
          setMonthAvailability([]);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description: err.message,
          });
        });
    }
  }, [currentMonth, filters, refresh]);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    var isSameDate = moment(day).isSame(selectedDate);

    if (modifiers.available && !modifiers.disabled && !isSameDate) {
      setSelectedDate(day);
      setCurrentPage(1);
      setTotalPages(1);
    }
  }, [selectedDate]);

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
    setCurrentPage(1);
    setTotalPages(1);
  }, []);

  const daysInMonth = useCallback((month: number, year: number) => {
    return new Date(year, month, 0).getDate();
  }, []);

  const handleEdit = useCallback(item => {
    item.office_id = {
      value: { id: item.office.id, working_days: item.office.working_days },
      label: item.office.name,
    };
    item.modality_id = { value: item.modality.id, label: item.modality.name };
    item.vaccines = item.vaccines.map((vac: any) => {
      return { value: vac.id, label: vac.name };
    });
    item.exams = item.exams.map((vac: any) => {
      return { value: vac.id, label: vac.name };
    });

    if (item.date_of_birth) {
      const ValidDateOfBirth =
        item.date_of_birth instanceof Date &&
        !isNaN(item.date_of_birth.getTime());

      if (!ValidDateOfBirth) {
        delete item.date_of_birth;
      }
    }

    setCurrentAppointment(item);
    setShowForm(true);
  }, []);

  const closeForm = useCallback(() => {

    setRefresh(!refresh);
    setCurrentPage(1);
    setCurrentAppointment(null);
    setShowForm(false);

    if (searchingSchedules) {
      customSearchFormRef.current?.setFieldValue('search', searchScheduleText);
    }
  }, [filters, refresh, searchScheduleText]);

  const handleFilterStatus = useCallback(
    value => {
      if (value) {
        setFilters({ ...filters, status: value.value });
      } else {
        setFilters({ ...filters, status: '' });
      }

      setSchedules([]);
      setCurrentPage(1);
      setTotalPages(1);
    },
    [filters],
  );

  const handleFilterUser = useCallback(
    value => {
      if (value) {
        setFilters({ ...filters, user_id: value.value });
      } else {
        setFilters({ ...filters, user_id: '' });
      }

      setSchedules([]);
      setCurrentPage(1);
      setTotalPages(1);
    },
    [filters],
  );

  const handleFilterModalities = useCallback(
    value => {
      if (value) {
        setFilters({
          ...filters,
          modalities: value.map((mod: any) => {
            return mod.value;
          }),
        });
      } else {
        setFilters({ ...filters, modalities: [] });
      }

      setSchedules([]);
      setCurrentPage(1);
      setTotalPages(1);
    },
    [filters],
  );

  const handleFilterOffices = useCallback(
    value => {
      if (value) {
        setFilters({
          ...filters,
          offices: value.map((of: any) => {
            return of.value;
          }),
        });
      } else {
        setFilters({
          ...filters,
          offices: offices.map(of => {
            return of.value;
          }),
        });
      }

      setSchedules([]);
      setCurrentPage(1);
      setTotalPages(1);
    },
    [filters, offices],
  );

  const disabledDays = useMemo(() => {
    const countDays = daysInMonth(
      currentMonth.getMonth() + 1,
      currentMonth.getFullYear(),
    );
    const dates: Date[] = [];
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();

    for (let i = 1; i <= countDays; i++) {
      let x = i.toString();
      x = i < 10 ? `0${i}` : `${i}`;
      const idx = monthAvailability.indexOf(x);
      if (idx === -1) {
        dates.push(new Date(year, month, i));
      }
    }
    return dates;
  }, [currentMonth, monthAvailability]);

  const handleViewMore = useCallback(
    () => {
      setCurrentPage(currentPage + 1);
    },
    [currentPage]
  );

  const handleViewMoreFound = useCallback(
    () => {
      setCurrentPageFound(currentPageFound + 1);
    },
    [currentPageFound]
  );

  var timeoutFound: any;
  const handleTextSearchFound = useCallback((value: any) => {
    clearTimeout(timeoutFound);

    timeoutFound = setTimeout(() => {
      if (value && value !== "") {
        setSearchingSchedules(true);
        setSearchScheduleText(value);
      } else {
        setSearchScheduleText("");
        setSearchingSchedules(false);
        setCurrentPageFound(1);
        setTotalPagesFound(1);
        setSchedulesFound([]);
      }
    }, 1000);

  }, [timeoutFound, searchingSchedules, searchScheduleText, schedulesFound, currentPageFound, totalPagesFound]);

  return (
    <Container>
      <Menu />

      <div className="content">
        <div className="breadcrumb">
          <h2>INÍCIO / AGENDAMENTOS</h2>
          <h2>Bem vindo, {user.name}</h2>
        </div>
        <Table>
          {!showForm ? (
            <>
              <div className="buttons">
                <button
                  type="button"
                  className="new-btn"
                  onClick={() => setShowForm(true)}
                >
                  Novo agendamento
                </button>
              </div>

              <div className="filters">
                <div className={`filters-header opened`}>
                  <div className="title">
                    <div>
                      <BiSearchAlt />
                      <h2>Encontrar agendamento</h2>
                    </div>

                    {
                      searchingSchedules &&
                      <button
                        type="button"
                        onClick={() => {
                          setSearchScheduleText("");
                          setSearchingSchedules(false);
                          setCurrentPageFound(1);
                          setTotalPagesFound(1);
                          setSchedulesFound([]);
                          customSearchFormRef?.current?.clearField('search');
                        }}
                        className="clear-search second"
                      >
                        Limpar
                      </button>
                    }
                  </div>
                </div>

                <div className={`content opened`}>
                  <div className="custom-search">
                    <FilterForm onSubmit={() => ""} ref={customSearchFormRef}>
                      <Input
                        type="text"
                        name="search"
                        placeholder="Digite o nome do cliente para buscar"
                        onInput={(e) => {
                          handleTextSearchFound(e.currentTarget.value);
                        }}
                        fullWidth
                        defaultValue={searchScheduleText}
                        disabled={loadingData && !searchingSchedules}
                      />
                    </FilterForm>
                  </div>
                </div>
              </div>

              {
                !searchingSchedules && (
                  <div className={`filters ${searchingSchedules ? "disabled" : ""}`}>
                    <div className={`filters-header opened`}>
                      <div className="title">
                        <div>
                          <BiFilterAlt />
                          <h2>Filtros</h2>
                        </div>
                        {
                          (filters.offices.length > 0 || filters.modalities.length > 0 || !!filters.status || !!filters.user_id) && (
                            <button
                              type="button"
                              onClick={() => {
                                setFilters({
                                  offices: [],
                                  modalities: [],
                                  search: '',
                                  status: '',
                                  user_id: '',
                                });

                                setTimeout(() => {
                                  filtersFormRef.current?.reset();
                                }, 300);
                              }}
                              className="clear-search second"
                            >
                              Limpar
                            </button>
                          )}
                      </div>
                    </div>

                    <div className={`content opened`}>
                      <div className="selects">
                        <FilterForm
                          onSubmit={() => console.log('')}
                          ref={filtersFormRef}
                          initialData={{
                            status: filters?.status ? { value: filters.status, label: filters.status } : '',
                            offices: filters?.offices?.length > 0 ? offices.filter((item: any) => filters.offices.includes(item.value)) : [],
                            modalities: filters?.modalities?.length > 0 ? modalities.filter((item: any) => filters.modalities.includes(item.value)) : [],
                            users: filters?.user_id ?
                              availableProfessionals.filter((item: any) => item.value === filters.user_id) ?
                                availableProfessionals.filter((item: any) => item.value === filters.user_id)[0] : ''
                              : '',
                          }}
                        >
                          <Select
                            name="status"
                            options={[
                              { value: 'Confirmado', label: 'Confirmado' },
                              { value: 'Cancelado', label: 'Cancelado' },
                              { value: 'Pendente', label: 'Pendente' },
                            ]}
                            onChange={handleFilterStatus}
                            isClearable
                            placeholder="Filtrar por status"
                            // label="Status"
                            noOptionsMessage={() => "Sem mais resultados..."}
                            isDisabled={loadingData}
                          />
                          <Select
                            name="offices"
                            options={offices}
                            isMulti
                            onChange={handleFilterOffices}
                            placeholder="Filtrar por unidade"
                            // label="Unidade"
                            noOptionsMessage={() => "Sem mais resultados..."}
                            isDisabled={loadingData}
                          />
                          <Select
                            name="users"
                            options={availableProfessionals}
                            onChange={handleFilterUser}
                            placeholder="Filtrar por profissional"
                            // label="Profissional"
                            isClearable
                            noOptionsMessage={() => "Sem mais resultados..."}
                            isDisabled={loadingData}
                          />
                          <Select
                            name="modalities"
                            options={modalities}
                            onChange={handleFilterModalities}
                            isMulti
                            placeholder="Filtrar por modalidade"
                            // label="Modalidade"
                            noOptionsMessage={() => "Sem mais resultados..."}
                            isDisabled={loadingData}
                          />
                        </FilterForm>
                      </div>
                    </div>
                  </div>
                )
              }

              <Content>

                <Schedules>
                  {loadingData ? (
                    <div className="loading">
                      <p className="alert">Carregando agendamentos...</p>
                    </div>
                  ) : (

                    searchingSchedules ? (
                      <>
                        {schedulesFound.length > 0 ? (
                          <>
                            {schedulesFound.map((item, index) => (
                              <SchedulesListFound
                                item={item}
                                key={index.toString()}
                                handleEdit={(instance) => handleEdit(instance)}
                                handleHistory={(instance) => {
                                  setShowHistory(true);
                                  setCurrentAppointment(instance);
                                }}
                              />
                            ))}

                            {
                              totalPagesFound > currentPageFound && (
                                <button type="button" className="view-more" onClick={handleViewMoreFound}>
                                  {loadingMoreFound ? "Carregando..." : "Ver mais"}
                                </button>
                              )
                            }
                          </>
                        ) : (
                          <p className="alert">
                            Nenhum agendamento encontrado.
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {schedules.length > 0 ? (
                          <>
                            {schedules.map((item, index) => (
                              <SchedulesList
                                item={item}
                                key={index.toString()}
                                handleEdit={(instance) => handleEdit(instance)}
                                handleHistory={(instance) => {
                                  setShowHistory(true);
                                  setCurrentAppointment(instance);
                                }}
                              />
                            ))}

                            {
                              totalPages > currentPage && (
                                <button type="button" className="view-more" onClick={handleViewMore}>
                                  {loadingMore ? "Carregando..." : "Ver mais"}
                                </button>
                              )
                            }
                          </>
                        ) : (
                          <p className="alert">
                            Nenhum agendamento registrado para o dia atual,
                            selecione um dia disponível no calendário ao lado.
                          </p>
                        )}
                      </>
                    )


                  )}
                </Schedules>

                {
                  !searchingSchedules &&
                  <BoxCalendar>
                    <DayPicker
                      weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                      // fromMonth={new Date()}
                      month={currentMonth}
                      disabledDays={[...disabledDays]}
                      modifiers={{
                        available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
                      }}
                      selectedDays={selectedDate}
                      onDayClick={handleDateChange}
                      onMonthChange={handleMonthChange}
                      months={[
                        'Janeiro',
                        'Fevereiro',
                        'Março',
                        'Abril',
                        'Maio',
                        'Junho',
                        'Julho',
                        'Agosto',
                        'Setembro',
                        'Outubro',
                        'Novembro',
                        'Dezembro',
                      ]}
                    />
                    <div className="sub-actions">
                      <button
                        type="button"
                        onClick={() => {
                          history.push(
                            `${process.env.PUBLIC_URL}/agendamentos/pdf`,
                            { schedules, selectedDate },
                          );
                        }}
                      >
                        <AiFillPrinter />
                        Imprimir lista
                      </button>
                    </div>
                  </BoxCalendar>
                }
              </Content>
            </>
          ) : (
            <Form
              appointment={currentAppointment}
              closeForm={closeForm}
            />
          )}
        </Table>

        {showHistory && (
          <HistoryModal
            item={currentAppointment}
            closeModal={() => {
              setShowHistory(false);
              setCurrentAppointment(null);
            }}
          />
        )}
      </div>
    </Container >
  );
};

export default Calendar;
