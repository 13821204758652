import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  padding: 0 30px;

  @media (max-width: 769px) {
    padding: 15px;
  }
`;

export const Container = styled.div`
  padding: 30px;

  @media (max-width: 769px) {
    padding: 15px;
  }
`;

export const List = styled.div`
  .new-btn {
    float: right;
    display: block;
    margin-bottom: 15px;
    background: #ffffff;
    border: 1px solid #5b7bab;
    padding: 7px 30px;
    font-weight: 500;
    border-radius: 5px;
    color: #5b7bab;
  }
`;

export const FormContainer = styled.div`
  position: relative;

  h2 {
    margin-bottom: 30px;
    color: #9d9d9d;
    font-weight: 500;

    @media (max-width: 769px) {
      font-size: 20px;
    }
  }

  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    line-height: 20px;
    background: transparent;
    border: 0;
    color: #5b7bab;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .break-line {
      width: 100%;
    }
  }
`;

export const HistoryContainer = styled.div`
  max-height: 40vh;
  overflow: auto;

  .history-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    &:nth-child(odd) {
      background: #f1f1f1;
    }

    strong {
      font-weight: 500;
      width: 50%;
    }
  }
`;
