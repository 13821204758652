import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiXCircle } from 'react-icons/fi';

import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';

import { User } from './index';
import { Office } from '../Offices';

import { FormContainer } from './styles';

interface UserFormData extends User {
  password: string;
}

interface FormData {
  user: User | null;
  closeForm(): void;
}

const FormModal: React.FC<FormData> = ({ user, closeForm }) => {
  const formRef = useRef<FormHandles>(null);

  const [offices, setOffices] = useState([]);
  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999?');

  const { addToast } = useToast();

  // Load Offices
  useEffect(() => {
    api.get('all-offices').then(response => {
      const officesAux = response.data.items.map((item: Office) => ({
        value: item.id,
        label: item.name,
      }));
      setOffices(officesAux);
    }).catch(err => {
      setOffices([]);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: err.message,
      });
    });
  }, []);

  useEffect(() => {
    if (user && user.phone?.length >= 15) {
      setPhoneMask('(99) 99999-9999');
    } else {
      setPhoneMask('(99) 9999-9999?');
    }
  }, [user]);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      try {
        formRef.current?.setErrors({});

        let schema;
        if (user) {
          schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string()
              .email('Digite um e-mail válido')
              .required('E-mail obrigatório'),
            phone: Yup.string().required('Telefone obrigatório'),
            password: Yup.string(),
            passwordConfirmation: Yup.string().oneOf(
              [Yup.ref('password')],
              'As senhas não conferem!',
            ),
            role_id: Yup.string().required('Permissão obrigatória'),
            offices: Yup.array()
              .of(Yup.number())
              .required('Unidade obrigatória'),
            receive_email_notifications: Yup.number().required(
              'Notificação obrigatória',
            ),
          });
        } else {
          schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string()
              .email('Digite um e-mail válido')
              .required('E-mail obrigatório'),
            phone: Yup.string().required('Telefone obrigatório'),
            password: Yup.string().required('Cidade obrigatória'),
            passwordConfirmation: Yup.string().oneOf(
              [Yup.ref('password'), undefined],
              'As senhas não conferem!',
            ),
            role_id: Yup.string().required('Permissão obrigatória'),
            offices: Yup.array()
              .of(Yup.number())
              .required('Unidade obrigatória'),
            receive_email_notifications: Yup.number().required(
              'Notificação obrigatória',
            ),
          });
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          name: data.name,
          email: data.email,
          phone: data.phone,
          password: data.password,
          role_id: data.role_id,
          offices: data.offices,
          receive_email_notifications: data.receive_email_notifications,
        };

        let response;
        if (user) {
          response = await api.put(`users/${user.id}`, formData);
        } else {
          response = await api.post('users', formData);
        }
        addToast({
          type: 'success',
          title: `Cadastro ${user ? 'atualizado' : 'realizado'}`,
          description: response.data.message,
        });

        closeForm();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [user],
  );

  return (
    <FormContainer>
      <h2>{user ? 'Editar' : 'Novo'} usuário</h2>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={user ? user : undefined}
      >
        <button type="button" onClick={() => closeForm()} className="close-btn">
          <FiXCircle size={30} />
        </button>

        <Input name="name" label="Nome" />
        <Input name="email" label="E-mail" />
        <Input name="password" label="Senha" type="password" />
        <Input
          name="passwordConfirmation"
          label="Confirmar senha"
          type="password"
        />
        <InputMask
          name="phone"
          label="Telefone"
          mask={phoneMask}
          formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
          maskChar={null}
          onChange={event => {
            if (event.target.value.length >= 15) {
              setPhoneMask('(99) 99999-9999');
            } else {
              setPhoneMask('(99) 9999-9999?');
            }
          }}
        />
        <Select
          name="offices"
          options={offices}
          isMulti
          label="Unidade(s)"
          placeholder="Selecione a unidade"
          noOptionsMessage={() => 'Todas as unidades foram selecionadas'}
        />
        <Select
          name="role_id"
          options={[
            { value: 1, label: 'ADMINISTRADOR' },
            { value: 2, label: 'SUB-ADMINISTRADOR' },
          ]}
          label="Permissão do usuário"
          placeholder="Selecionar"
        />
        <Select
          name="receive_email_notifications"
          options={[
            { value: 0, label: 'NÃO RECEBER' },
            { value: 1, label: 'RECEBER' },
          ]}
          label="Receber notificações por e-mail"
          placeholder="Selecionar"
          defaultValue={
            user
              ? {
                value: user.receive_email_notifications,
                label: user.receive_email_notifications
                  ? 'RECEBER'
                  : 'NÃO RECEBER',
              }
              : null
          }
        />
        <div className="break-line" />
        <Button type="submit">Salvar</Button>
      </Form>
    </FormContainer>
  );
};

export default FormModal;
