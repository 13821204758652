import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from './Auth';
import api, { baseURL } from '../services/api';
import { useToast } from './Toast';
import { useHistory } from 'react-router-dom';

// import { Container } from './styles';

const ValidateTokenProvider: React.FC = () => {
  const { addToast, clearToasts } = useToast();
  const { signOut }: any = useAuth();
  const history = useHistory();

  const [ignoreUrls] = useState<string[]>([
    'users/reset-password',
    'users/reset-password/verify',
    'auth/login',
  ]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    var tryNumber = 1;

    api.interceptors.response.use(response => {
      return response
    }, err => {
      new Promise(async (resolve, reject) => {
        // console.log("err.config.url", err.config.url.replace(err.config.baseURL, ''));
        // const originalReq = err.config;
        // if (err?.response?.status == 401 && err.config && !err.config._retry && !ignoreUrls.includes(err.config.url)) {
        if (err?.response?.status == 401 && tryNumber <= 1 && !ignoreUrls.includes(err.config.url.replace(err.config.baseURL, ''))) {
          // originalReq._retry = true;
          tryNumber++;

          const token = localStorage.getItem('@VacSaoBento:token');

          if (token) {
            api.defaults.headers.authorization = `Bearer ${token}`;
          }

          await api.post(`${baseURL}auth/verify`, {
            cancelToken: source.token
          }).then(res => {
            if (res.data === true) {
              resolve(res);
            }
          }).catch(err => {
            reject(err);
          });

        } else {
          resolve(err);
        }
      }).then(res => {
        return;
      })
        .catch(err => {
          clearToasts();
          addToast({
            timer: 9000,
            type: 'info',
            title: `Oops! Sua sessão expirou.`,
            description: `Efetue o login novamente.`,
          });

          signOut();

          tryNumber = 1;
        })
    })
  }, []);

  return <></>;
}

export default ValidateTokenProvider;

