import React, { useEffect, useState, useCallback } from 'react';

import Menu from '../../components/Menu';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';

import api from '../../services/api';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import Form from './form';

import { Container } from './styles';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert'; // Import

export interface User {
  id: number;
  name: string;
  phone: string;
  email: string;
  role_id: {
    label: string;
    value: number;
  };
  modalities: {
    id?: number;
    name?: string;
    label?: string;
    value?: number;
  }[];
  role: {
    name: string;
    id: number;
  };
  offices: {
    id?: number;
    name?: string;
    label?: string;
    value?: number;
  }[];
  receive_email_notifications: boolean;
}

const Users: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);

  const { user } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('users', {
        params: {
          search: filteredAttr,
          page: currentPage,
        },
      })
      .then(response => {
        setUsers(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      }).catch(err => {
        setUsers([]);
        setLoadingData(false);
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description: err.message,
        });
      });
  }, [showForm, filteredAttr, currentPage, refresh]);

  const handleEdit = useCallback((item: User) => {
    item.offices = item.offices.map(of => {
      return { value: of.id, label: of.name };
    });
    item.role_id = {
      value: item.role.id,
      label: item.role.name,
    };
    setCurrentUser(item);
    setShowForm(true);
  }, []);

  const closeForm = useCallback(() => {
    setCurrentUser(null);
    setShowForm(false);
    setFilteredAttr('');
  }, []);

  const handleDelete = useCallback(
    item => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Deletar usuário?</h1>
              <p>Gostaria mesmo de deletar o usuário {item.name}?</p>
              <button onClick={onClose}>Não</button>
              <button
                onClick={() => {
                  api.delete(`users/${item.id}`).then(resp => {
                    addToast({
                      type: 'success',
                      title: `Usuário deletado`,
                      description: `O usuário ${item.name} foi deletado com sucesso`,
                    });

                    setRefresh(!refresh);
                  }).catch(err => {
                    addToast({
                      type: 'error',
                      title: 'Falha na requisição',
                      description: err.message,
                    });
                  });
                  setFilteredAttr('');
                  onClose();
                }}
              >
                Sim, deletar!
              </button>
            </div>
          );
        },
      });
    },
    [refresh],
  );

  return (
    <Container>
      <Menu />

      <div className="content">
        <div className="breadcrumb">
          <h2>INÍCIO / USUÁRIOS</h2>
          <h2>Bem vindo, {user.name}</h2>
        </div>
        <Table>
          {!showForm ? (
            <>
              <div className="filters to-right">
                <input
                  type="text"
                  name="search"
                  placeholder="Filtrar usuários"
                  onChange={event => setFilteredAttr(event.target.value)}
                  value={filteredAttr}
                  className="filter-input"
                />
                <button
                  type="button"
                  className="new-btn"
                  onClick={() => setShowForm(true)}
                >
                  Novo
                </button>
              </div>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <td>Nome</td>
                      <td>Telefone</td>
                      <td>Permissão</td>
                      <td>Qtd unidades</td>
                      <td>Unidade(s)</td>
                      <td>Açõe(s)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingData ? (
                      <tr className="loading">
                        <td colSpan={6}>
                          <p className="alert">Carregando usuários...</p>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {users.length > 0 ? (
                          users.map(user => (
                            <tr key={user.id.toString()}>
                              <td>{user.name}</td>
                              <td>{user.phone}</td>
                              <td>{user.role.name}</td>
                              <td>{user.offices.length}</td>
                              <td>
                                {user.offices.map((of, index) =>
                                  index !== 0 ? ` ,${of.name}` : `${of.name}`,
                                )}
                              </td>
                              <td>
                                <button type="button" title="Editar">
                                  <BiEdit
                                    onClick={() => handleEdit(user)}
                                    size={22}
                                  />
                                </button>
                                <button type="button" title="Deletar">
                                  <MdDeleteForever
                                    onClick={() => handleDelete(user)}
                                    size={22}
                                  />
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <p className="alert">
                                Nenhum resultado encontrado
                              </p>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <Pagination
                  pages={totalPages}
                  currentPage={currentPage}
                  setPage={p => setCurrentPage(p)}
                />
              </div>
            </>
          ) : (
            <Form user={currentUser} closeForm={() => closeForm()} />
          )}
        </Table>
      </div>
    </Container>
  );
};

export default Users;
