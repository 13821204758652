import React, { useEffect, useState } from 'react';
import { Wrapper, Container } from './styles';
import { Appointment } from '../Calendar';
import { format } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from '../../assets/logo.jpeg';
import Menu from '../../components/Menu';
import { useToast } from '../../hooks/Toast';
import moment from 'moment';

const SchedulesPdf: React.FC = () => {
  const [schedules, setSchedules] = useState<Appointment[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();



  useEffect(() => {
    if (location.state.schedules) {
      setSchedules(location.state.schedules);
    }

    if (location.state.selectedDate) {
      setSelectedDate(location.state.selectedDate);
    }

  }, [location]);

  async function handlePrint() {
    window.print();
  }

  return (
    <Container>
      <Menu />
      <div className="content">
        <div className="breadcrumb">
          <h2>
            INÍCIO / AGENDAMENTOS /{' '}
            {selectedDate !== null && selectedDate !== undefined
              ? format(new Date(selectedDate), "dd'-'MM'-'yyyy")
              : ''}
          </h2>
        </div>
        <Wrapper>
          <div className="buttons">
            <button
              type="button"
              className="back-button"
              onClick={() => history.push('/agenda')}
            >
              Voltar
            </button>
            <button
              type="button"
              className="print-button"
              onClick={handlePrint}
            >
              Imprimir
            </button>
          </div>

          <div className="pdf-container">
            <table>
              <thead>
                <tr>
                  <td colSpan={6}>
                    <table>
                      <tr>
                        <td colSpan={2}>
                          <div className="logo-box">
                            <img src={Logo} alt="Logo Vacinas São Bento" />
                          </div>
                        </td>
                        <td className="text-right">
                          <strong>Total atendimentos: </strong> <br />
                          {schedules.length}
                        </td>
                        <td className="text-right">
                          <strong>Data referente: </strong>
                          <br />
                          {selectedDate !== null && selectedDate !== undefined
                            ? format(new Date(selectedDate), "dd'/'MM'/'yyyy")
                            : ''}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>NOME</th>
                  <th>TELEFONE</th>
                  <th>ENDEREÇO</th>
                  <th>DATA/HORA</th>
                  <th>
                    MODALIDADE/
                    <br />
                    UNIDADE
                  </th>
                  <th>VACINAS</th>
                  <th>EXAMES</th>
                </tr>
              </thead>
              <tbody>
                {
                  loading ?
                    <>
                      <tr>
                        <td colSpan={7}>Carregando...</td>
                      </tr>
                    </>
                    :
                    schedules &&
                    schedules.map((item, i) => (
                      <>
                        <tr key={item.id.toString()}>
                          <td>{item.name}</td>
                          <td>{item.phone}</td>
                          <td>{item.address ? item.address : 'Não informado'}</td>

                          <td>
                            {`${new Date(item?.scheduling_date).getUTCDate()}/${new Date(item?.scheduling_date).getUTCMonth() + 1}/${new Date(item?.scheduling_date).getUTCFullYear()}`}
                            
                            <br />
                            {item.time_start.value}
                          </td>
                          <td>
                            {item.modality.name}/
                            <br />
                            {item.office.name}
                          </td>
                          <td>
                            {item.vaccines.length === 0 && "-"}
                            {item.vaccines.map((vaccine, vi) => (
                              <p key={vaccine.name.toString()}>{vaccine.name},</p>
                            ))}
                          </td>
                          <td>
                            {item.exams.length === 0 && "-"}
                            {item.exams.map((exam, vi) => (
                              <p key={exam.name.toString()}>{exam.name},</p>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <strong>Observações: </strong>{item.observation || "Nenhuma observação..."}
                          </td>
                          <td colSpan={3}>
                            <strong>Status: </strong>{`AGENDAMENTO ${item.status}` || "Nenhum status..."}
                          </td>
                        </tr>
                      </>
                    ))
                }
              </tbody>
            </table>
          </div>
        </Wrapper>
      </div>
    </Container>
  );
};

export default SchedulesPdf;
