import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useToast } from '../../hooks/Toast';

import Input from '../../components/Input';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';
import Button from '../../components/Button';

import { Office } from './index';

import { FormContainer } from './styles';
import api from '../../services/api';
import { FiXCircle, FiDelete } from 'react-icons/fi';

import cep from 'cep-promise';

interface FormData {
  unit: Office | null;
  closeForm(): void;
}

interface OfficeVaccines {
  vaccine_id: number;
  available: number;
}

interface Vaccines {
  label: string;
  value: number;
  disabled?: boolean;
}

const FormVaccines: React.FC<FormData> = ({ unit, closeForm }) => {
  const formRef = useRef<FormHandles>(null);

  const [availability, setAvailability] = useState<OfficeVaccines[]>([
    { vaccine_id: -1, available: -1 },
  ]);
  const [vaccines, setVaccines] = useState<Vaccines[]>([]);
  const [office, setOffice] = useState<Office | null>(unit);
  const [initialData, setInitialData] = useState<OfficeVaccines[]>([]);

  const { addToast } = useToast();

  const [statuses, setStatuses] = useState<any>([
    { value: 0, label: 'Indisponível' },
    { value: 1, label: 'Disponível' },
  ]);

  useEffect(() => {
    if (unit) {
      var aux: any = [];
      unit.vaccines.map(vaccine => {
        aux.push({
          vaccine_id: vaccine.pivot.vaccine_id,
          available: vaccine.pivot.available,
        });
      });
      setAvailability([...aux]);
    }
  }, [unit]);

  useEffect(() => {
    api.get('all-vaccines').then(res => {
      if (res.data.items.length > 0) {
        res.data.items.map((item: any) => {
          vaccines.push({ value: parseInt(item.id), label: item.name });
        });
      }

      setVaccines([...vaccines]);
    });
  }, []);

  const addItem = useCallback(() => {
    setAvailability(state => [
      ...state,
      {
        vaccine_id: -1,
        available: -1,
      },
    ]);
  }, [availability]);

  const removeItem = useCallback(
    (id: number) => {
      setAvailability(availability.filter((item, i) => i !== id));
    },
    [availability],
  );

  const handleChangeVaccine = useCallback(
    (val, index) => {
      availability[index].vaccine_id = val.value;
      setAvailability([...availability]);
    },
    [availability, vaccines],
  );

  const handleChangeStatus = useCallback(
    (val, index) => {
      var i = statuses.indexOf(val);

      availability[index].available = val.value;
      setAvailability([...availability]);
    },
    [availability],
  );

  const handleSubmit = useCallback(
    async data => {
      const vaccines = availability.map((item: any) => {
        return {
          vaccine_id: item.vaccine_id === -1 ? undefined : item.vaccine_id,
          available: item.available === -1 ? undefined : item.available,
        };
      });
      data.vaccines = vaccines;

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          vaccines: Yup.array().of(
            Yup.object().shape({
              vaccine_id: Yup.string().required('Vacina obrigatória'),
              available: Yup.string().required('Disponibilidade obrigatória'),
            }),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let response;

        if (unit) {
          response = await api.put(`office-vaccines/${unit.id}`, data);
        }

        addToast({
          type: 'success',
          title: `Vacinas atualizadas com sucesso!`,
          description: response?.data.message,
        });

        closeForm();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [availability],
  );

  return (
    <FormContainer>
      <h2>{`${office?.name} - VACINAS DISPONÍVEIS`}</h2>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={initialData ? initialData : undefined}
      >
        <button type="button" onClick={() => closeForm()} className="close-btn">
          <FiXCircle size={30} />
        </button>

        <div className="attendance-days">
          <label>Selecione as vacinas disponíveis</label>
          <div className="days">
            {availability.length !== 0 ? (
              <>
                {availability.map((item, index) => {
                  return (
                    <>
                      <Scope path={`vaccines[${index}]`} key={index.toString()}>
                        <div className="new-day vaccine">
                          <Select
                            name="vaccine_id"
                            options={vaccines}
                            placeholder="Vacina(s)"
                            onChange={val => handleChangeVaccine(val, index)}
                            noOptionsMessage={() => 'Sem resultados'}
                            value={
                              item.vaccine_id !== -1
                                ? vaccines.filter(
                                    vac =>
                                      vac.value ===
                                      availability[index].vaccine_id,
                                  )[0]
                                : undefined
                            }
                            isOptionDisabled={option =>
                              availability.filter(
                                item => item.vaccine_id === option.value,
                              )[0] !== undefined
                            }
                          />
                          <Select
                            name="available"
                            options={statuses}
                            placeholder="Disponibilidade"
                            onChange={val => handleChangeStatus(val, index)}
                            value={
                              item.available !== -1
                                ? statuses[item.available]
                                : undefined
                            }
                          />
                          <button
                            type="button"
                            className="delete"
                            onClick={() => removeItem(index)}
                            disabled={availability.length <= 1}
                          >
                            <FiDelete />
                          </button>
                        </div>
                      </Scope>
                    </>
                  );
                })}
              </>
            ) : (
              <div className="alert-box">
                <p className="alert">Nenhum item encontrado.</p>
              </div>
            )}
          </div>
          <button className="add-day" type="button" onClick={() => addItem()}>
            Adicionar novo
          </button>
        </div>

        <div className="to-right">
          <Button type="submit">Salvar</Button>
        </div>
      </Form>
    </FormContainer>
  );
};

export default FormVaccines;
