import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useToast } from '../../hooks/Toast';

import Input from '../../components/Input';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';
import Button from '../../components/Button';

import { Office } from './index';

import { FormContainer } from './styles';
import api from '../../services/api';
import { FiXCircle, FiDelete } from 'react-icons/fi';

import cep from 'cep-promise';

interface FormData {
  unit: Office | null;
  closeForm(): void;
}

interface WeekDay {
  day: number;
  modality_id: number;
  start: string;
  end: string;
  type?: string;
}

interface UnitFormData {
  name: string;
  phone: string;
  cep: string;
  address: string;
  city: string;
  uf: string;
  number: number;
  homecare_interval?: any;
  clinic_interval?: any;
  drivethru_interval?: any;
  exams_homecare_interval?: any;
  exams_clinic_interval?: any;
  exams_drivethru_interval?: any;
  // covid_interval?: any;
  working_days: {
    day: number | undefined;
    hour_period: string[];
    type?: string;
  }[];
}

interface EditInput {
  type: 'day' | 'start' | 'end';
  index: number;
  value: string;
}

interface UnitDay {
  pivot: {
    working_day_id: number;
    hour_start: string;
    hour_end: string;
    modality_id: number;
    type?: string;
  };
}

const FormModal: React.FC<FormData> = ({ unit, closeForm }) => {
  const formRef = useRef<FormHandles>(null);

  const [days, setDays] = useState<WeekDay[]>([
    // { modality_id: -1, day: -1, start: '', end: '', type: '' },
  ]);
  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999?');
  const [modalities] = useState([
    { value: 1, label: 'Atendimento na clinica' },
    { value: 2, label: 'Domiciliar' },
    { value: 3, label: 'Drive-Thru' },
    // { value: 4, label: 'Exames laboratoriais' },
  ]);
  const [office, setOffice] = useState<Office | null>(unit);
  const [activeTab, setActiveTab] = useState('Na Clínica');
  const [saving, setSaving] = useState<boolean>(false);

  const { addToast } = useToast();

  const daysOfWeek = [
    { value: 0, label: 'Domingo' },
    { value: 1, label: 'Segunda-feira' },
    { value: 2, label: 'Terça-feira' },
    { value: 3, label: 'Quarta-feira' },
    { value: 4, label: 'Quinta-feira' },
    { value: 5, label: 'Sexta-feira' },
    { value: 6, label: 'Sabado' },
  ];

  const loadCurrentDays = useCallback(
    (unitDays: WeekDay[]) => {
      unitDays.map(async (day: WeekDay, index: number) => {
        await formRef.current!.setFieldValue('working_days[0].day', 1);
      });
    },
    [formRef, daysOfWeek],
  );

  useEffect(() => {
    if (office !== null && office.working_days !== undefined) {
      const unitDays: WeekDay[] = [];
      office.working_days.map((day: UnitDay) => {
        unitDays.push({
          modality_id: day.pivot.modality_id,
          day: day.pivot.working_day_id,
          start: day.pivot.hour_start,
          end: day.pivot.hour_end,
          type: day.pivot.type,
        });
      });

      if (unitDays.length >= 1) {
        setDays([...unitDays]);
      }

      loadCurrentDays(unitDays);
    }
  }, [office]);

  useEffect(() => {
    if (office && office.phone?.length >= 15) {
      setPhoneMask('(99) 99999-9999');
    } else {
      setPhoneMask('(99) 9999-9999?');
    }
  }, [office]);

  const addDay = ({ modality_id }: any) => {
    setDays(state => [
      ...state,
      {
        modality_id,
        day: -1,
        start: '',
        end: '',
        type: ''
      },
    ]);
  };

  const removeDay = (id: number) => {
    setDays(days.filter((item, i) => i !== id));
  };

  const handleChange = (data: EditInput) => {
    if (data.type !== 'day') {
      days[data.index][data.type] = data.value;
      setDays([...days]);
    }
  };

  const handleChangeWeekDay = (val: any, index: number) => {
    const daysAux = days;
    daysAux[index].day = val.value.toString();
    setDays([...daysAux]);
  };

  const handleChangeType = (val: any, index: number) => {
    const daysAux = days;
    daysAux[index].type = val.value;
    setDays([...daysAux]);
  };

  const handleChangeModality = (val: any, index: number) => {
    const daysAux = days;
    daysAux[index].modality_id = val.value.toString();
    setDays([...daysAux]);
  };

  const handleSubmit = useCallback(
    async (data: UnitFormData) => {
      setSaving(true);
      try {
        const working_days = days.map((day: WeekDay) => {
          return {
            modality_id: day.modality_id === -1 ? undefined : day.modality_id,
            day: day.day === -1 ? undefined : day.day,
            hour_period: [day.start, day.end],
            type: day.type || ''
          };
        });

        // console.log("SUBMITED DATA ==> ", data)

        const formData: UnitFormData = {
          name: data.name,
          phone: data.phone,
          cep: data.cep,
          city: data.city,
          address: data.address,
          number: data.number,
          uf: data.uf,
          working_days,
          homecare_interval: data.homecare_interval,
          clinic_interval: data.clinic_interval,
          drivethru_interval: data.drivethru_interval,
          exams_homecare_interval: data.exams_homecare_interval,
          exams_clinic_interval: data.exams_clinic_interval,
          exams_drivethru_interval: data.exams_drivethru_interval,
          // covid_interval: data.covid_interval,
        };

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cep: Yup.string().required('CEP obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
          city: Yup.string().required('Cidade obrigatória'),
          address: Yup.string().required('Endereço obrigatório'),
          number: Yup.number().required('Número obrigatório'),
          uf: Yup.string().required('UF obrigatório'),
          homecare_interval: Yup.string().nullable(),
          clinic_interval: Yup.string().nullable(),
          drivethru_interval: Yup.string().nullable(),
          exams_homecare_interval: Yup.string().nullable(),
          exams_clinic_interval: Yup.string().nullable(),
          exams_drivethru_interval: Yup.string().nullable(),
          // covid_interval: Yup.string().nullable(),
          working_days: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required('Tipo da agenda obrigatório'),
              modality_id: Yup.number().required('Modalidade obrigatória'),
              day: Yup.number().required('Dia obrigatório'),
              hour_period: Yup.array().of(
                Yup.string().required('Horário obrigatório'),
              ),
            }),
          ),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        let response;
        if (office) {
          response = await api.put(`offices/${office.id}`, formData);
        } else {
          response = await api.post('offices', formData);
        }
        addToast({
          type: 'success',
          title: `Cadastro ${office ? 'atualizado' : 'realizado'}`,
          description: response.data.message,
        });

        if (!office) {
          setOffice(response.data.item);
        }

        setSaving(false);
        // closeForm();
      } catch (err) {
        setSaving(false);
        console.log("err ==> ", err);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Atenção!',
            description:
              'Preencha todos os campos obrigatórios e tente novamente.',
            timer: 8000
          });

          return;
        } else {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
          });
        }
      }
    },
    [days, activeTab, office, saving],
  );

  const handleCep = useCallback(
    (inputCep: string) => {
      cep(inputCep).then(response => {
        formRef.current?.setFieldValue(
          'address',
          `${response.street}, ${response.neighborhood}`,
        );
        formRef.current?.setFieldValue('city', `${response.city}`);
        formRef.current?.setFieldValue('uf', `${response.state}`);
      });
    },
    [formRef],
  );

  const handleStatus = useCallback(
    async (active: boolean) => {
      if (office) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>Confirmar ação?</h1>
                <p>Gostaria de {active ? 'ativar' : 'desativar'} a unidade?</p>
                <button onClick={onClose}>Não</button>
                <button
                  onClick={async () => {
                    await api.put(`offices/${office.id}`, {
                      active: active,
                    });
                    addToast({
                      type: 'success',
                      title: `Cadastro ${active ? 'ativado' : 'desativado'}`,
                    });
                    office.active = active;
                    setOffice({ ...office });
                    onClose();
                  }}
                >
                  Sim, {active ? 'ativar' : 'desativar'}!
                </button>
              </div>
            );
          },
        });
      }
    },
    [unit, office],
  );

  return (
    <FormContainer>
      <h2>{office ? 'Editar' : 'Nova'} unidade</h2>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={office ? office : undefined}
      >
        <button type="button" onClick={() => closeForm()} className="close-btn">
          <FiXCircle size={30} />
        </button>
        <Input name="name" label="Nome" />
        <InputMask
          name="phone"
          label="Telefone"
          mask={phoneMask}
          formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
          maskChar={null}
          onChange={event => {
            if (event.target.value.length >= 15) {
              setPhoneMask('(99) 99999-9999');
            } else {
              setPhoneMask('(99) 9999-9999?');
            }
          }}
        />
        <InputMask
          name="cep"
          label="CEP"
          mask="99999-999"
          maskChar={null}
          onChange={event => {
            if (event.target.value.length >= 9) {
              handleCep(event.target.value);
            }
          }}
        />
        <Input name="uf" label="UF" />
        <Input name="city" label="Cidade" />
        <Input name="address" label="Endereço" />
        <Input type="number" name="number" label="Número" />

        <hr />

        <h3>Intervalos de atendimentos para vacinas</h3>

        <div className="intervals">
          <Input type="number" name="clinic_interval" label="Intervalo Na Clínica em min. (Opcional, padrão 15min)" placeholder="15" />
          <Input type="number" name="homecare_interval" label="Intervalo Domiciliar em min. (Opcional, padrão 60min)" placeholder="60" />
          <Input type="number" name="drivethru_interval" label="Intervalo Drive-Thru em min. (Opcional, padrão 30min)" placeholder="30" />
        </div>


        <hr />

        <h3>Intervalos de atendimentos para exames</h3>

        <div className="intervals">
          <Input type="number" name="exams_clinic_interval" label="Intervalo Na Clínica em min. (Opcional, padrão 15min)" placeholder="15" />
          <Input type="number" name="exams_homecare_interval" label="Intervalo Domiciliar em min. (Opcional, padrão 60min)" placeholder="60" />
          <Input type="number" name="exams_drivethru_interval" label="Intervalo Drive-Thru em min. (Opcional, padrão 30min)" placeholder="30" />
        </div>

        <hr />

        {/* <Input type="number" name="covid_interval" label="Intervalo Covid em min. (Opcional, padrão 60min)" /> */}

        <div className="attendance-days">
          <h3>Agendas de atendimento</h3>

          <div className="tabs">
            <div className="header">
              <div
                className={activeTab === "Na Clínica" ? "active" : ""}
                onClick={() => {
                  setActiveTab('Na Clínica');
                }}
              >
                Na Clínica
              </div>
              <div
                className={activeTab === "Domiciliar" ? "active" : ""}
                onClick={() => {
                  setActiveTab('Domiciliar');
                }}
              >
                Domiciliar
              </div>
              <div
                className={activeTab === "Drive-Thru" ? "active" : ""}
                onClick={() => {
                  setActiveTab('Drive-Thru');
                }}
              >
                Drive-Thru
              </div>
            </div>

            <div className="content">

              <div className="days">
                {days.length !== 0 && (
                  <>
                    {
                      // days.filter(function (event) {
                      //   return event.modality_id === (activeTab === "Na Clínica" ?
                      //     1 : activeTab === "Domiciliar" ?
                      //       2 : 3);
                      // }).map((day, index) => {
                      days.map((day, index) => {
                        if (activeTab === "Na Clínica" && day.modality_id === 1 || activeTab === "Domiciliar" && day.modality_id === 2 || activeTab === "Drive-Thru" && day.modality_id === 3) {
                          return (
                            <>
                              <Scope
                                path={`working_days[${index}]`}
                                key={index.toString()}
                              >
                                <div className="new-day">
                                  <Input
                                    type="hidden"
                                    name="modality_id"
                                    value={activeTab === "Na Clínica" ?
                                      1 : activeTab === "Domiciliar" ?
                                        2 : 3
                                    }
                                  />
                                  {/* <Select
                                  name="modality_id"
                                  options={modalities}
                                  placeholder="Modalidade"
                                  value={
                                    day.modality_id !== -1
                                      ? modalities[day.modality_id - 1]
                                      : undefined
                                  }
                                  onChange={val => handleChangeModality(val, index)}
                                /> */}
                                  <Select
                                    name="type"
                                    options={[
                                      { label: "Vacinas", value: "vacinas" },
                                      { label: "Exames", value: "exames" },
                                    ]}
                                    placeholder="Tipo da agenda"
                                    value={
                                      day.type && !!day.type && day.type === "vacinas" ? { value: "vacinas", label: "Vacinas" } :
                                        day.type && !!day.type && day.type === "exames" ? { value: "exames", label: "Exames" } :
                                          undefined
                                    }
                                    onChange={val => handleChangeType(val, index)}
                                    isSearchable={false}
                                  />
                                  <Select
                                    name="day"
                                    options={daysOfWeek}
                                    placeholder="Dia da semana"
                                    value={
                                      day.day !== -1 ? daysOfWeek[day.day] : undefined
                                      // day.day !== -1 ? daysOfWeek.filter(item => item.value === day.day)[0] : undefined
                                    }
                                    onChange={val => handleChangeWeekDay(val, index)}
                                    isSearchable={false}
                                  />
                                  <Input
                                    placeholder="Horario inicio"
                                    name="hour_period[0]"
                                    onFocus={e => (e.currentTarget.type = 'time')}
                                    onBlur={e => {
                                      e.currentTarget.type = 'text';
                                      e.currentTarget.placeholder = 'Horario inicio';
                                    }}
                                    defaultValue={day.start}
                                    onChange={e =>
                                      handleChange({
                                        type: 'start',
                                        value: e.currentTarget.value,
                                        index,
                                      })
                                    }
                                  />
                                  <Input
                                    placeholder="Horario fim"
                                    name="hour_period[1]"
                                    onFocus={e => (e.currentTarget.type = 'time')}
                                    onBlur={e => {
                                      e.currentTarget.type = 'text';
                                      e.currentTarget.placeholder = 'Horario fim';
                                    }}
                                    defaultValue={day.end}
                                    onChange={e =>
                                      handleChange({
                                        type: 'end',
                                        value: e.currentTarget.value,
                                        index,
                                      })
                                    }
                                  />
                                  <button
                                    type="button"
                                    className="delete"
                                    onClick={() => removeDay(index)}
                                    disabled={days.length <= 1}
                                  >
                                    <FiDelete />
                                  </button>
                                </div>
                              </Scope>
                            </>
                          );
                        }

                      })}
                  </>
                )}
              </div>
              <button className="add-day" type="button" onClick={() => addDay({
                modality_id: activeTab === "Na Clínica" ?
                  1 : activeTab === "Domiciliar" ?
                    2 : 3
              })}>
                Adicionar novo
              </button>

            </div>
          </div>
        </div>

        {office && (
          <Button
            type="button"
            className={office.active ? 'cancel' : 'confirm'}
            onClick={() => handleStatus(!office.active)}
          >
            {office.active ? 'Desativar' : 'Ativar'}
          </Button>
        )}
        <div className="to-right">
          <Button type="submit" disabled={saving}>{saving ? "Salvando..." : "Salvar"}</Button>
        </div>
      </Form>
    </FormContainer>
  );
};

export default FormModal;
