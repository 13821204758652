import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;

  @media print {
    @page {
      margin: 0;
      size: 22cm 31cm;
      margin: 5mm 5mm 5mm 5mm;
    }

    .fixed,
    .breadcrumb,
    .buttons,
    .print-button,
    .back-button {
      display: none !important;
    }

    table {
      border: none;

      thead {
        tr {
          border: 1px solid #000;

          &:nth-child(1) {
            border: none;
          }
        }
      }

      tbody {
        border: 1px solid #000;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .content {
    animation: ${appearFromTop} 1s;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding: 0 50px;

    @media (max-width: 769px) {
      padding: 0 15px;
    }

    .breadcrumb {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 60px;
      margin-top: 60px;
      align-items: center;
      flex-wrap: wrap;

      animation: ${appearFromTop} 1s;

      @media (max-width: 769px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 25px 0;
      }

      > div {
        display: flex;
        align-items: center;

        @media (max-width: 769px) {
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #dfdfdf;
          flex-direction: column;
          align-items: normal;
        }
      }

      h2 {
        font-size: 20px;
        color: #9d9d9d;
        font-weight: 400;
        margin-right: 30px;

        & + h2 {
          margin-right: 0;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .buttons {
    display: flex;
    justify-content: center;
    padding: 0 0px 40px 0;

    button {
      transition: 0.4s;
      background: #6788ba;
      color: #fff;
      border: none;
      padding: 10px;
      height: 40px;
      margin: 0 10px;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgb(0 0 0 / 25%);

      &:hover {
        background: transparent;
        color: #6788ba;
        border: 1px solid #6788ba;
      }

      &:nth-child(1) {
        background: transparent;
        color: #6788ba;
        border: 1px solid #6788ba;

        &:hover {
          background: #6788ba;
          color: #fff;
        }
      }
    }
  }

  .pdf-container {
    display: block;
    padding: 20px;
    background: #fff;
    margin: 0 auto 30px;
    width: calc(794px + 40px);
    border-radius: 5px;
    max-width: 100%;
  }

  table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    border: solid white !important;

    thead {
      tr {
        border-bottom: 1px solid #000;
        &:nth-child(1) {
          height: 90px;
        }
      }

      th {
        color: #000;
        font-size: 13px;
        text-align: left;
        padding: 10px 5px;
        border-bottom: 1px solid black;
        border-collapse: collapse;
      }


      td.text-right {
        text-align: right;
        padding: 0 10px;
      }
    }

    tbody {
      tr {
        -webkit-print-color-adjust: exact;
        &:nth-child(4n + 3) {
          background: #f1f1f1;
        }

        &:nth-child(4n + 4) {
          background: #f1f1f1;
        }

        td {
          font-size: 12px;
          padding: 5px;
          text-transform: uppercase;
          /* border: 1px solid black; */
          border-collapse: collapse;
        }
      }
    }

    .logo-box img {
      height: 60px;
      padding: 10px;
    }
  }
`;
