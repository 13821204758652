import React from 'react';
import { FiCalendar, FiClock } from 'react-icons/fi';
import { HiClock, HiEye } from 'react-icons/hi';
import { Appointment } from './styles';
import moment from "moment";

// import { Container } from './styles';

interface PagePropsI {
  item: any;
  handleEdit(item: any): void;
  handleHistory(item: any): void;
}

const SchedulesListFound: React.FC<PagePropsI> = ({ item, handleEdit, handleHistory }) => {
  return (
    <Appointment
    // isAdmin={user.role_id === 1}
    // onClick={() => {
    //   if (user.role_id !== 1) {
    //     handleEdit(item);
    //   }
    // }}
    >
      <span>
        <FiCalendar size={20} />
        {moment(item.scheduling_date).format("DD/MM/YYYY")}
        <span />
        <FiClock size={20} />
        {item.time_start.label}
      </span>
      <div>
        <div className={`status ${item.status}`}>
          <span />
          {item.status}
        </div>
        <div>
          <strong>{item.name}</strong>
          {item.phone}
        </div>
        <div>
          <strong>{item.office.name}</strong>
          {item.modality.name}
        </div>

        {/* {
                                  user.role_id === 1 && ( */}
        <div className="action-buttons">
          {/* <strong>Ações</strong> */}

          <div>
            <button
              className="history-button"
              type="button"
              onClick={() => {
                handleEdit(item);
              }}
              title="Visualizar detalhes"
            >
              <p>Detalhes</p>
              <HiEye />
            </button>
            <button
              className="history-button"
              type="button"
              onClick={() => {
                handleHistory(item);
              }}
              title="Histórico de edições"
            >
              <p>Histórico</p>
              <HiClock />
            </button>
          </div>
        </div>
        {/* )
                                 } */}

      </div>
    </Appointment>
  );
}

export default SchedulesListFound;
