import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import api from '../../services/api';

import { useToast } from '../../hooks/Toast';

import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';
import Button from '../../components/Button';
import Textarea from '../../components/Textarea';

import { FiXCircle } from 'react-icons/fi';

import { Appointment } from './index';
import { FormContainer } from './styles';

import { Office } from '../Offices';
import getDay from 'date-fns/getDay';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { FaWhatsappSquare } from 'react-icons/fa';
import CreatableSelect from '../../components/CreatableSelect';
import moment from 'moment';
import { format } from 'date-fns';
import { dateIsoToDate } from '../../utils/dateIsoToDate';
const throttledQueue = require('throttled-queue');

interface FormData {
  appointment: Appointment | null;
  closeForm(): void;
}

interface CurOffice {
  id: number;
  working_days: {
    name: string;
    id: number;
    pivot: {
      modality_id: number;
    };
  }[];
}

interface Vaccine {
  name: string;
  id: number;
}

interface SelectType {
  label: string;
  value: number | string;
}

const FormC: React.FC<FormData> = ({ appointment, closeForm }) => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const [offices, setOffices] = useState<any[]>([]);
  const [modalities] = useState([
    { value: 1, label: 'Atendimento na Clínica' },
    { value: 2, label: 'Domiciliar' },
    { value: 3, label: 'Drive-Thru' },
    // { value: 4, label: 'Domiciliar teste Covid-19' },
  ]);
  const [vaccines, setVaccines] = useState([]);
  const [exams, setExams] = useState([]);
  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999?');
  const [currentModality, setCurrentModality] = useState<SelectType | null>();
  const [currentOffice, setCurrentOffice] = useState<CurOffice | null>(null);
  const [currentOfficeData, setCurrentOfficeData] = useState<any | null>(null);
  const [arrayOfDays] = useState([
    'DOMINGO',
    'SEGUNDA-FEIRA',
    'TERÇA-FEIRA',
    'QUARTA-FEIRA',
    'QUINTA-FEIRA',
    'SEXTA-FEIRA',
    'SÁBADO',
  ]);
  const [shedulingDate, setSchedulingDate] = useState<Date | null>();
  const [availableHours, setAvailableHours] = useState([]);
  const [timeStart, setTimeStart] = useState<SelectType | null>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [availableProfessionals, setAvailableProfessionals] = useState<any[]>([]);
  const [loadingVaccines, setLoadingVaccines] = useState<boolean>(false);
  const [loadingExams, setLoadingExams] = useState<boolean>(false);
  const [loadingOffices, setLoadingOffices] = useState<boolean>(false);
  const [hasProducts, setHasProducts] = useState<string[]>([]);

  const [customersFound, setCustomersFound] = useState<any[]>([]);
  const [searchingCustomers, setSearchingCustomers] = useState<boolean>(false);

  const { user } = useAuth();

  useEffect(() => {
    api.get('all-professionals').then(response => {
      setAvailableProfessionals(
        response.data.items.map((user: any) => {
          return {
            label: user.name,
            value: user.id,
          };
        }),
      );
    }).catch(err => {
      setAvailableProfessionals([]);
      addToast({
        type: 'error',
        title: 'Falha na requisição',
        description: err.message,
      });
    });
  }, []);

  // Load Units / Modalities
  useEffect(() => {
    setLoadingOffices(true);
    api.get('user/offices').then(response => {
      setLoadingOffices(false);

      setOffices(
        response.data.map((of: any) => {
          return {
            label: of.name,
            value: {
              id: of.id,
              working_days: of.working_days,
            },
          };
        }),
      );
    })
      .catch(err => {
        setLoadingOffices(false);

        addToast({
          timer: 9000,
          type: 'error',
          title: `Oops! Ocorreu um erro.`,
          description: `Não foi possível carregar as unidades, verifique sua conexão ou contate o suporte.`,
        });
      });
  }, []);

  // Load Vacines
  useEffect(() => {
    setLoadingVaccines(true);

    if (currentOffice) {
      api
        .get('all-vaccines', { params: { office_id: currentOffice?.id } })
        .then(response => {
          const vaccineList = response.data.items.map((item: Vaccine) => ({
            value: item.id,
            label: item.name,
          }));
          setVaccines(vaccineList);
          setLoadingVaccines(false);
        }).catch(err => {
          setVaccines([]);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description: err.message,
          });
        });
    }
  }, [currentOffice]);

  // Load Exams
  useEffect(() => {
    setLoadingExams(true);

    if (currentOffice) {
      api
        .get('all-exams', { params: { office_id: currentOffice?.id } })
        .then(response => {
          const examsList = response.data.items.map((item: Vaccine) => ({
            value: item.id,
            label: item.name,
          }));
          setExams(examsList);
          setLoadingExams(false);
        }).catch(err => {
          setExams([]);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description: err.message,
          });
        });
    }
  }, [currentOffice]);

  useEffect(() => {
    // console.log("APPOINMENT ==> ", appointment)

    if (appointment) {
      setCurrentModality(appointment.modality_id);
      setCurrentOffice(appointment.office_id.value);
      setSchedulingDate(appointment.scheduling_date);
      setTimeStart(appointment.time_start);
      const userAux = appointment.user ? { label: appointment.user.name, value: appointment.user.id } : undefined;
      formRef?.current?.setFieldValue('user_id', userAux);
      if(appointment.date_of_birth) {
        formRef?.current?.setFieldValue('date_of_birth', format(dateIsoToDate(appointment.date_of_birth), 'dd/MM/yyyy'));
      }
    }

    if (appointment && appointment.phone?.length >= 15) {
      setPhoneMask('(99) 99999-9999');
    } else {
      setPhoneMask('(99) 9999-9999?');
    }
  }, [appointment]);

  useEffect(() => {
    if (shedulingDate) {
      var day_id = getDay(new Date(shedulingDate));
      loadAvailableHours(day_id, new Date(shedulingDate));
    }
  }, [shedulingDate]);

  useEffect(() => {
    let available = false;
    if (currentOffice && currentModality) {
      currentOffice.working_days.filter(wd => {
        if (wd.pivot.modality_id === currentModality.value) {
          available = true;
        }
      });
      if (!available) {
        addToast({
          timer: 9000,
          type: 'info',
          title: `Não foi possível carregar os dias`,
          description: `A unidade selecionada não possui nenhum dia registrado para a modalide, acesse a página de unidades e configure a página de atendimento.`,
        });

        setHasProducts([]);
        formRef.current?.clearField('vaccines');
        formRef.current?.clearField('exams');
      } else {
      }
    }
    formRef.current?.clearField('scheduling_date');
    formRef.current?.clearField('time_start');
  }, [currentModality, currentOffice]);

  useEffect(() => {
    if (currentOffice) {
      api.get(`offices/${currentOffice.id}`).then(response => {
        setCurrentOfficeData(response.data);
      }).catch(err => {
        setCurrentOfficeData([]);
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description: err.message,
          timer: 10000
        });
      });
    }
  }, [currentOffice]);

  const handleOffice = useCallback(value => {
    if (value.value !== currentOffice) {
      setSchedulingDate(null);
      setTimeStart(null);
    }
    setCurrentOffice(value.value);
  }, []);

  const handleModality = useCallback(value => {
    if (value !== currentModality) {
      setSchedulingDate(null);
      setTimeStart(null);
    }
    setCurrentModality(value);
  }, []);

  const isAvailableDay = useCallback(
    date => {
      if (currentOffice && currentModality) {
        const day = getDay(date);
        const array: any = [];
        currentOffice.working_days.map(async item => {
          if (currentModality.value === item.pivot.modality_id) {
            array.push(item.id);
          }
        });

        return array.includes(day);
      }
      return true;
    },
    [currentOffice, currentModality],
  );

  const loadAvailableHours = async (day_id: number, date: Date) => {
    // setHoursIsLoading(true);
    var schedule_day = new Date(date);
    try {
      if (currentOffice && currentModality) {
        const response = await api.get(`available-hours`, {
          params: {
            office_id: currentOffice.id,
            modality_id: currentModality.value,
            week_day: arrayOfDays[day_id],
            date: `${schedule_day.getFullYear()}-${schedule_day.getMonth() + 1
              }-${schedule_day.getDate()}`,
          },
        });

        // ---
        const auxArray: any = [];
        response.data.available_hours_array?.map((hour: any) => {
          const now = moment().format('YYYY-MM-DD HH:mm');
          const _hour = moment(schedule_day).set({ hour: parseInt(hour.split(":")[0]), minute: parseInt(hour.split(":")[1]) });
          const _selectedDate = moment(schedule_day).format('YYYY-MM-DD');

          // Exclusão de datas e horários para feriados
          if (currentOffice.id === 21) {
            if (moment(schedule_day).format('YYYY-MM-DD') === "2022-04-15" || moment(schedule_day).format('YYYY-MM-DD') === "2022-04-21") {
              const _startHour = moment(`${_selectedDate} 13:30`).format('YYYY-MM-DD HH:mm');
              const _endHour = moment(`${_selectedDate} 19:00`).format('YYYY-MM-DD HH:mm');
              if (moment(_hour).isAfter(_startHour) && moment(_hour).isBefore(_endHour)) {
                auxArray.push(hour);
              }
            }
            // Formatação dos horários em dias úteis
            else {
              if (moment(_hour).isAfter(now)) {
                auxArray.push(hour);
              }
            }
          } else {
            if (moment(_hour).isAfter(now)) {
              auxArray.push(hour);
            }
          }
        });

        const options = auxArray.map(
          (item: any) => ({
            value: item,
            label: item,
          }),
        );
        // ---

        // const options = response.data.available_hours_array.map(
        //   (item: any) => ({
        //     value: item,
        //     label: item,
        //   }),
        // );
        setAvailableHours(options);
        setHasProducts(response.data.available_categories_for_attendance);

        if (!response.data.available_categories_for_attendance.includes('vacinas')) {
          formRef.current?.clearField('vaccines');
        }

        if (!response.data.available_categories_for_attendance.includes('exames')) {
          formRef.current?.clearField('exams');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleScheduleDate = useCallback(data => {
    setSchedulingDate(data);
    setTimeStart(null);
  }, []);

  const handleTimeStart = useCallback(data => {
    if (data) {
      setTimeStart(data);
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      data.office_id = data.office_id.id;
      data.time_start = timeStart?.value;
      setLoadingSubmit(true);

      if (data.date_of_birth) {
        data.date_of_birth = data.date_of_birth.split('/').reverse().join('-');
      } else {
        data.date_of_birth = undefined;
      }

      if (typeof data.name === 'object') {
        data.name = data.name.name;
      }

      // console.log("Submited data ==> ", data);
      data.scheduling_date = moment(data.scheduling_date).format('YYYY-MM-DD');

      let throttle = throttledQueue(1, 1000);
      throttle(async function () {
        try {
          let schema;

          schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string().nullable(),
            phone: Yup.string()
              .min(14, 'Informe um telefone válido.')
              .required('Telefone obrigatório'),
            modality_id: Yup.string().required('Modalidade obrigatória'),
            office_id: Yup.string().required('Unidade obrigatória'),
            address: data.modality_id === 2 ?
              Yup.string().required('Endereço obrigatório') : Yup.string().nullable(),
            date_of_birth: Yup.date()
              .required('A data de nascimento é obrigatória.')
              .nullable(),
            scheduling_date: Yup.date()
              .required('A data de agendamento é obrigatória.')
              .nullable(),
            time_start: Yup.string().required(
              'O horário de agendamento é obrigatório.',
            ),
            user_id: Yup.string().nullable(),
            vaccines: hasProducts && hasProducts.includes("vacinas") && !hasProducts.includes("exames")
              || hasProducts && hasProducts.includes("vacinas") && hasProducts.includes("exames") && data.exams.length === 0 ?
              Yup.array().of(Yup.number()).required('É obrigatório informar uma vacina ou exame.')
              : Yup.string().nullable(),
            exams: hasProducts && hasProducts.includes("exames") && !hasProducts.includes("vacinas")
              || hasProducts && hasProducts.includes("exames") && hasProducts.includes("vacinas") && data.vaccines.length === 0 ?
              Yup.array()
                .of(Yup.number())
                .required('É obrigatório informar um exame ou vacina.') : Yup.string().nullable(),
          });

          // else if (data.modality_id === 4) {
          //   schema = Yup.object().shape({
          //     name: Yup.string().required('Nome obrigatório'),
          //     email: Yup.string().nullable(),
          //     phone: Yup.string()
          //       .min(14, 'Informe um telefone válido.')
          //       .required('Telefone obrigatório'),
          //     modality_id: Yup.string().required('Modalidade obrigatória'),
          //     office_id: Yup.string().required('Unidade obrigatória'),
          //     address: Yup.string().required('Endereço obrigatório'),
          //     // vaccines: Yup.array()
          //     //   .of(Yup.number())
          //     //   .required('A(s) vacina(s) é(são) obrigatória(s).'),
          //     date_of_birth: Yup.date()
          //       .required('A data de nascimento é obrigatória.')
          //       .nullable(),
          //     scheduling_date: Yup.date()
          //       .required('A data de agendamento é obrigatória.')
          //       .nullable(),
          //     time_start: Yup.string().required(
          //       'O horário de agendamento é obrigatório.',
          //     ),
          //     user_id: Yup.string().nullable(),
          //   });
          // }

          await schema.validate(data, {
            abortEarly: false,
          });

          let response;
          if (appointment) {
            response = await api.put(`schedules/${appointment.id}`, data);
          } else {
            response = await api.post('admin-create-schedules', data);
          }

          addToast({
            type: 'success',
            title: `Agendamento ${appointment ? 'atualizado' : 'cadastrado'}`,
            description: response.data.message,
          });

          setLoadingSubmit(false);

          closeForm();
        } catch (err) {
          setLoadingSubmit(false);
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);

            formRef.current?.setErrors(errors);

            return;
          } else {
            addToast({
              type: 'error',
              title: 'Falha na requisição',
              description:
                'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
            });
          }
        }
      });
    },
    [timeStart, currentOffice, hasProducts],
  );

  const handleConfirm = useCallback(async () => {
    try {
      if (appointment) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>Confirmar ação?</h1>
                <p>Gostaria de confirmar o atendimento?</p>
                <button onClick={onClose}>Não</button>
                <button
                  onClick={() => {
                    api.put(`schedules/${appointment.id}`, {
                      status: 'Confirmado',
                    })
                      .then(res => {
                        addToast({
                          type: 'success',
                          title: 'Agendamento confirmado com sucesso!',
                        });
                        closeForm();
                        onClose();
                      })
                      .catch(err => {
                        console.log('api catch', err);
                        onClose();
                        addToast({
                          type: 'error',
                          title: 'Falha na confirmação!',
                          description: 'Tente novamente',
                        });
                      });
                  }}
                >
                  Sim, confirmar!
                </button>
              </div>
            );
          },
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha na confirmação!',
        description: 'Confirme os dados e tente novamente',
      });
    }
  }, []);

  const handleCancel = useCallback(async () => {
    try {
      if (appointment) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>Confirmar ação?</h1>
                <p>Gostaria de cancelar o atendimento?</p>
                <button onClick={onClose}>Não</button>
                <button
                  onClick={() => {
                    api.put(`schedules/${appointment.id}`, {
                      status: 'Cancelado',
                    })
                      .then(res => {
                        addToast({
                          type: 'success',
                          title: 'Agendamento cancelado com sucesso!',
                        });

                        closeForm();
                        onClose();
                      })
                      .catch(err => {
                        console.log(err);
                        onClose();
                        addToast({
                          type: 'error',
                          title: 'Falha no cancelamento!',
                          description: 'Confirme os dados e tente novamente',
                        });
                      });
                  }}
                >
                  Sim, cancelar!
                </button>
              </div>
            );
          },
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha no cancelamento!',
        description: 'Confirme os dados e tente novamente',
      });
    }
  }, []);

  let customerAutocompleteTimeout: any;
  const handleCustomerAutocomplete = useCallback((text) => {
    clearTimeout(customerAutocompleteTimeout);

    customerAutocompleteTimeout = setTimeout(() => {
      if (text && text !== "") {
        setSearchingCustomers(true);

        api
          .get('schedules', {
            params: {
              search: text,
            },
          })
          .then(response => {
            const aux = response.data.items
              .filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t.phone === v.phone)) === i)
              .slice(0, 20)
              .map((instance: any) => {
                return {
                  label: `${instance.name.substring(0, 30)}... - ${instance.phone || instance.email || instance.address}`,
                  value: {
                    id: instance.id,
                    name: instance.name,
                    phone: instance.phone,
                    email: instance.email,
                    address: instance.address,
                    date_of_birth: instance.date_of_birth,
                  }
                }
              });

            setCustomersFound([...aux]);
            setSearchingCustomers(false);
            // console.log("AUX ===> ", aux);
            // console.log("RESPOSTAS ===> ", response.data.items);
          }).catch(err => {
            setSearchingCustomers(false);
            setCustomersFound([]);

            addToast({
              type: 'error',
              title: 'Falha na requisição',
              description: err.message,
            });
          });
      } else {
        setCustomersFound([]);
        setSearchingCustomers(false);
      }
    }, 1200);
  }, [customerAutocompleteTimeout, customersFound, searchingCustomers]);

  return (
    <FormContainer>
      <h2>{appointment ? 'Editar' : 'Novo'} agendamento</h2>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={
          appointment ? {
            ...appointment,
            user_id: appointment.user ? { label: appointment.user.name, value: appointment.user.id } : undefined
          } : undefined
        }
      >
        <button type="button" onClick={() => closeForm()} className="close-btn">
          <FiXCircle size={30} />
        </button>


        <h3>Dados pessoais</h3>

        {/* <Input name="name" label="Nome completo" /> */}
        <CreatableSelect
          name="name"
          label="Nome completo"
          placeholder="Informe o nome completo"
          formatCreateLabel={(text) => {
            return `Utilizar "${text}"`
          }}
          allowCreateWhileLoading={false}
          noOptionsMessage={() => "Busque na base de dados da Clínica"}
          onInputChange={handleCustomerAutocomplete}
          options={customersFound}
          isLoading={searchingCustomers}
          loadingMessage={() => "Buscando clientes..."}
          isClearable
          onChange={(opt) => {
            if (opt) {
              var instance = opt.value;

              // console.log('instance ==> ', instance);
              // console.log("date_of_birth ==> ", date_of_birth);

              if (!opt.__isNew__) {
                formRef.current?.setFieldValue('name', instance.name);

                if (instance && instance.phone?.length >= 15) {
                  setPhoneMask('(99) 99999-9999');
                } else {
                  setPhoneMask('(99) 9999-9999?');
                }

                if (instance.date_of_birth) {
                  formRef.current?.setFieldValue('date_of_birth', moment(instance.date_of_birth).format('DD/MM/YYYY'));
                }

                formRef.current?.setFieldValue('email', instance.email || undefined);
                formRef.current?.setFieldValue('phone', instance.phone || undefined);
                // formRef.current?.setFieldValue('address', instance.address || undefined);
              }
            } else {
              formRef.current?.clearField('email');
              formRef.current?.clearField('phone');
              formRef.current?.clearField('date_of_birth');
            }
          }}
        />

        <Input name="email" label="E-mail" type="email" />

        <div className="form-field">
          <InputMask
            name="phone"
            label="Telefone"
            mask={phoneMask}
            formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
            maskChar={null}
            onChange={event => {
              if (event.target.value.length >= 15) {
                setPhoneMask('(99) 99999-9999');
              } else {
                setPhoneMask('(99) 9999-9999?');
              }
            }}
          />
          {appointment && (
            <a
              href={`https://api.whatsapp.com/send?phone=55${appointment?.phone}`}
              target="_blank"
            >
              <FaWhatsappSquare
                className={
                  appointment && appointment?.phone.length > 14
                    ? 'active whatsapp'
                    : 'inactive whatsapp'
                }
              />
            </a>
          )}
        </div>

        <Input name="address" label="Endereço" />
        {/* <DatePicker
          name="date_of_birth"
          label="Data de nascimento"
          isClearable
          dateFormat="dd/MM/yyyy"
          showYearDropdown
        /> */}

        <InputMask
          name='date_of_birth'
          label='Data de nascimento'
          placeholder='__/__/____'
          mask='99/99/9999'
          formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
          maskChar={null}
        />


        <hr />

        <h3>Dados do agendamento</h3>

        <Select
          label="Modalidade"
          name="modality_id"
          options={modalities}
          placeholder="Selecione a modalidade"
          onChange={handleModality}
          noOptionsMessage={() => "Nenhum resultado encontrado"}
        />
        {
          loadingOffices ? (
            <Input name="loading_offices" label={"Unidade"} placeholder="Carregando lista de unidades..." disabled />
          ) : (
            <Select
              label="Unidade"
              name="office_id"
              options={offices}
              placeholder="Selecione a unidade"
              onChange={handleOffice}
              noOptionsMessage={() => "Nenhum resultado encontrado"}
            />
          )
        }
        <DatePicker
          name="scheduling_date"
          label="Data do agendamento"
          placeholderText={
            currentOffice && currentModality
              ? `Selecione a data do agendamento`
              : `Escolha uma unidade para liberar as datas de agendamento`
          }
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
          excludeDates={
            currentOffice?.id !== 21 ?
              [
                new Date("2022-04-16"),
              ]
              :
              []
          }
          filterDate={date => isAvailableDay(date)}
          onSelect={handleScheduleDate}
          disabled={!currentOffice || !currentModality}
          selected={shedulingDate ? shedulingDate : null}
        />
        <Select
          name="time_start"
          options={availableHours}
          label="Horário do agendamento"
          placeholder={
            shedulingDate
              ? `Selecione o horário do atendimento`
              : `Escolha uma data para liberar os horários disponíveis`
          }
          noOptionsMessage={() =>
            'Selecione uma data para carregar os horários'
          }
          isDisabled={shedulingDate === null}
          value={timeStart ? timeStart : null}
          onChange={handleTimeStart}
        />
        <Select
          name="user_id"
          options={availableProfessionals}
          label="Profissional responsável (opcional)"
          placeholder={
            `Selecione um profissional...`
          }
          isClearable
          noOptionsMessage={() =>
            'Nenhum profissional encontrado'
          }
        />

        <hr />

        <h3>Selecione os itens do agendamento</h3>

        {
          !shedulingDate && (
            <div className="alert-box">
              <p className="alert">Antes, preencha os "Dados do agendamento".</p>
            </div>
          )
        }

        {
          hasProducts && hasProducts.includes("vacinas") ? (
            loadingVaccines ?
              <Input name="loading_vaccines" label={"Vacinas"} placeholder="Carregando lista de vacinas desta unidade..." disabled />
              :
              <Select
                name="vaccines"
                options={vaccines}
                label="Vacinas"
                placeholder="Selecione a(s) vacina(s)"
                noOptionsMessage={() =>
                  'Sem mais resultados para a unidade selecionada.'
                }
                isMulti
              />
          ) : hasProducts && !hasProducts.includes("vacinas") && shedulingDate && (
            <Select
              name="vaccines"
              options={[]}
              label="Vacinas"
              placeholder="Nenhuma agenda disponível para as opções selecionadas."
              noOptionsMessage={() =>
                'Sem mais resultados para a unidade selecionada.'
              }
              isMulti
              isDisabled
            />
          )
        }

        {
          hasProducts && hasProducts.includes("exames") ? (
            loadingExams ?
              <Input name="loading_vaccines" label={"Exames"} placeholder="Carregando lista de exames desta unidade..." disabled />
              :
              <Select
                name="exams"
                options={exams}
                label="Exames"
                placeholder="Selecione o(s) exame(s)"
                noOptionsMessage={() =>
                  'Sem mais resultados para a unidade selecionada.'
                }
                isMulti
              />
          ) : hasProducts && !hasProducts.includes("exames") && shedulingDate && (
            <Select
              name="exams"
              options={[]}
              label="Exames"
              placeholder="Nenhuma agenda disponível para as opções selecionadas."
              noOptionsMessage={() =>
                'Sem mais resultados para a unidade selecionada.'
              }
              isMulti
              isDisabled
            />
          )
        }

        {/* {
          loadingVaccines ? (
            <Input name="loading_vaccines" label={currentModality?.value === 4 ? "Vacinas" : "Vacinas"} placeholder="Carregando lista de vacinas desta unidade..." disabled />
          ) : (
            <Select
              name="vaccines"
              options={vaccines}
              label={currentModality?.value === 4 ? "Vacinas" : "Vacinas"}
              placeholder={currentModality?.value === 4 ? "Não compatível com esta modalidade" : "Selecione a(s) vacina(s)"}
              noOptionsMessage={() =>
                'Escolha uma unidade para liberar as vacinas disponíveis'
              }
              isMulti
              isDisabled={currentModality?.value === 4}
            />
          )
        } */}

        <Textarea name="observation" label="Observações" fullWidth />

        {currentOfficeData && (
          <div className="office-addres">
            <h3>Dados da unidade</h3>
            <strong>Endereço:</strong> {currentOfficeData.address},{' '}
            {currentOfficeData.number} - {currentOfficeData.city}/
            {currentOfficeData.uf}
            <br />
            <strong>Telefone:</strong> {currentOfficeData.phone}
          </div>
        )}
        {appointment && (
          <div>
            {appointment.status !== 'Confirmado' && (
              <Button type="button" className="confirm" onClick={handleConfirm}>
                Confirmar
              </Button>
            )}
            {appointment.status !== 'Cancelado' && (
              <Button type="button" className="cancel" onClick={handleCancel}>
                Cancelar
              </Button>
            )}
          </div>
        )}
        <div className="to-right">
          <Button type="submit" disabled={loadingSubmit}>
            {appointment
              ? loadingSubmit
                ? `Atualizando...`
                : `Atualizar`
              : loadingSubmit
                ? `Salvando...`
                : `Salvar`}
          </Button>
        </div>
      </Form>
    </FormContainer>
  );
};

export default FormC;
