import axios from 'axios';

// const baseURL = 'http://localhost:8080/v1/';
const baseURL = 'https://api.vacinasaobento.com.br/v1/';

const api = axios.create({
  baseURL
});

export default api;
export {
  baseURL
};
