import React, { useEffect, useState, useCallback } from 'react';

import Menu from '../../components/Menu';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';

import Form from './form';

import { Container } from './styles';
import api from '../../services/api';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { BiEdit } from 'react-icons/bi';

export interface Item {
  name: string;
  id: number;
  description: string;
}

const Vaccines: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [currentItem, setCurrentItem] = useState<Item | null>(null);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { user } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('vaccines', { params: { search: filteredAttr, page: currentPage } })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      })
      .catch(err => {
        setItems([]);
        setLoadingData(false);
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description: err.message,
        });
      });
  }, [showForm, filteredAttr, currentPage]);

  const handleEdit = useCallback((item: Item) => {
    setCurrentItem(item);
    setShowForm(true);
  }, []);

  const closeForm = useCallback(() => {
    setCurrentItem(null);
    setShowForm(false);
  }, []);

  return (
    <Container>
      <Menu />

      <div className="content">
        <div className="breadcrumb">
          <h2>INÍCIO / CADASTRO DE VACINAS</h2>
          <h2>Bem vindo, {user.name}</h2>
        </div>
        <Table>
          {!showForm ? (
            <>
              <div className="filters to-right">
                <input
                  type="text"
                  name="search"
                  placeholder="Filtrar vacinas"
                  onChange={event => setFilteredAttr(event.target.value)}
                  value={filteredAttr}
                  className="filter-input"
                />
                <button
                  type="button"
                  className="new-btn"
                  onClick={() => setShowForm(true)}
                >
                  Novo
                </button>
              </div>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <td>Nome</td>
                      <td>Descrição</td>
                      <td>Açõe(s)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingData ? (
                      <tr className="loading">
                        <td colSpan={6}>
                          <p className="alert">Carregando itens...</p>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {items.length > 0 ? (
                          <>
                            {items.map(item => (
                              <tr key={item.id!.toString()}>
                                <td>{item.name}</td>
                                <td>
                                  {item.description
                                    ? item.description
                                    : 'Não possui'}
                                </td>

                                <td>
                                  <button type="button" title="Editar">
                                    <BiEdit
                                      onClick={() => handleEdit(item)}
                                      size={22}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={3}>
                              <p className="alert">
                                Nenhum resultado encontrado
                              </p>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <Pagination
                  pages={totalPages}
                  currentPage={currentPage}
                  setPage={p => setCurrentPage(p)}
                />
              </div>
            </>
          ) : (
            <Form item={currentItem} closeForm={() => closeForm()} />
          )}
        </Table>
      </div>
    </Container>
  );
};

export default Vaccines;
