import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error, Label } from './styles';

interface Props extends InputProps {
  name: string;
  fullWidth?: boolean;
  label?: string;
}

const InputMask: React.FC<Props> = ({
  name,
  fullWidth = false,
  label,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Label fullWidth={fullWidth} className="label">
      {!!label && <strong>{label}</strong>}
      <Container isErrored={!!error} isFocused={isFocused} className="input">
        <ReactInputMask
          ref={inputRef}
          defaultValue={defaultValue}
          {...rest}
          onFocus={() => handleInputFocus()}
          onBlur={() => handleInputBlur()}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="c53030" size={20} />
          </Error>
        )}
      </Container>
    </Label>
  );
};
export default InputMask;
