import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .content {
    display: flex;
    flex: 1;
    place-items: center;
    flex-direction: column;
    padding: 0 50px;

    @media (max-width: 769px) {
      padding: 0 15px;
    }

    .breadcrumb {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 60px;
      margin-top: 60px;
      align-items: center;
      flex-wrap: wrap;

      animation: ${appearFromTop} 1s;

      @media (max-width: 769px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 25px 0;
      }

      > div {
        display: flex;
        align-items: center;

        @media (max-width: 769px) {
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #dfdfdf;
          flex-direction: column;
          align-items: normal;
        }
      }

      h2 {
        font-size: 20px;
        color: #9d9d9d;
        font-weight: 400;
        margin-right: 30px;

        & + h2 {
          margin-right: 0;
        }
      }
    }
  }
`;

export const FormContainer = styled.div`
  h2 {
    margin-bottom: 30px;
    color: #9d9d9d;
    font-weight: 500;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .close-btn {
      position: absolute;
      right: 0;
      top: -60px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #6788ba;
    }

    button[type='submit'] {
      border-radius: 5px;
    }

    .break-line {
      width: 100%;
    }
  }
`;
