import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .content {
    display: flex;
    flex: 1;
    place-items: center;
    flex-direction: column;
    padding: 0 50px 30px;

    @media (max-width: 769px) {
      padding: 0 15px 15px;
    }

    .breadcrumb {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 60px;
      margin-top: 60px;
      align-items: center;
      flex-wrap: wrap;

      animation: ${appearFromTop} 1s;

      @media (max-width: 769px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 25px 0 15px;
      }

      > div {
        display: flex;
        align-items: center;

        @media (max-width: 769px) {
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #dfdfdf;
          flex-direction: column;
          align-items: normal;
        }
      }

      h2 {
        font-size: 20px;
        color: #9d9d9d;
        font-weight: 400;
        margin-right: 30px;

        & + h2 {
          margin-right: 0;
        }
      }

      form {
        > div {
          width: 300px;
          height: 40px;
          margin-bottom: 0;
          .react-select__multi-value__label {
            max-width: 60px;
          }

          .react-select__value-container--is-multi {
            max-height: 38px;
            overflow: auto;
          }
        }

        @media (max-width: 769px) {
          flex: 1;

          > div {
            width: 100%;
          }
        }
      }
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      flex-wrap: wrap;
    }

    .alert {
      background: #f1f1f1;
      padding: 20px;
      font-weight: 500;
      text-align: center;
      color: #333;
      border-radius: 5px;
    }

    h3 {
      margin-bottom: 20px;
      color: #6788ba;
    }

    .chart-box {
      height: 300px;
    }
  }
`;

export const TopBlocks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  flex-wrap: wrap;

  .block {
    background-color: #fff;
    width: calc(100% / 4 - 20px);
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */

    @media (max-width: 1024px) {
      width: calc(100% / 2 - 10px);
      margin-top: 20px;
    }

    h2 {
      color: #6788ba;
      font-weight: 600;
      font-size: 38px;
    }

    h4 {
      color: #6788ba;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      padding: 0 5px;
    }

    p {
      color: #c2c2c2;
      margin-top: 20px;
      font-size: 14px;
    }

    .loading {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h2 {
        background: #e2e2e2;
        width: 60px;
        height: 40px;
        margin-bottom: 15px;
        animation: shine 1s ease-in-out infinite;
        animation-fill-mode: forwards;
      }

      h4 {
        background: #e2e2e2;
        width: 230px;
        max-width: 90%;
        height: 24px;
        animation: shine 1s ease-in-out infinite;
        animation-fill-mode: forwards;
      }

      p {
        background: #e2e2e2;
        width: 180px;
        max-width: 80%;
        height: 18px;
        animation: shine 1s ease-in-out infinite;
        animation-fill-mode: forwards;
      }
    }
  }
`;

export const PieChart = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  width: calc(40% - 15px);
  display: flex;
  padding: 30px;
  flex-direction: column;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 30px;
  }

  .loading {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    .chart {
      height: 300px;
      width: 300px;
      max-width: 90%;
      border-radius: 50%;
      background: #e2e2e2;
    }
  }
`;

export const BarChart = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  width: calc(60% - 15px);
  display: flex;
  padding: 30px;
  flex-direction: column;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */

  @media (max-width: 1024px) {
    width: 100%;
  }

  .loading {
    .chart {
      height: 300px;
      width: 100%;
      background: #e2e2e2;
    }
  }
`;

export const LineChart = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */

  .loading {
    .chart {
      height: 300px;
      width: 100%;
      background: #e2e2e2;
    }
  }
`;
