import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';

interface User {
  email: string;
  name: string;
  modalities: {
    id: number;
    name: string;
  }[];
  offices: {
    id: number;
    name: string;
  }[];
  role_id: number;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredencials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredencials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@VacSaoBento:token');
    const user = localStorage.getItem('@VacSaoBento:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('auth/login', { email, password });

    const { token, user } = response.data;

    localStorage.setItem('@VacSaoBento:token', token.token);
    localStorage.setItem('@VacSaoBento:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token.token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@VacSaoBento:token');
    localStorage.removeItem('@VacSaoBento:user');

    history?.push(`${process.env.PUBLIC_URL}/login`);

    setData({} as AuthState);
  }, []);

  const verifyToken = useCallback(async () => {
    try {
      const response = await api.post('auth/verify');
      if (!response.data === true) {
        signOut();
      }
    } catch (err) {
      signOut();
    }
  }, [signOut]);

  // useEffect(() => {
  //   verifyToken();
  // }, [verifyToken]);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
