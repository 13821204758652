import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { Container, Error, Label } from './styles';
import { FiAlertCircle } from 'react-icons/fi';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  fullWidth?: boolean;
  label?: string;
}

const DatePicker: React.FC<Props> = ({
  name,
  fullWidth = false,
  label,
  ...rest
}) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(defaultValue || null);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);
  return (
    <Label fullWidth={fullWidth} className="label">
      {!!label && <strong>{label}</strong>}
      <Container isErrored={!!error}>
        <ReactDatePicker
          ref={datepickerRef}
          selected={date}
          onChange={setDate}
          locale={ptBR}
          {...rest}
          // customInput={
          //   <ReactInputMask
          //     mask="99/99/9999"
          //     placeholder="mm/dd/yyyy"
          //     formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
          //     maskChar={null}
          //   />
          // }
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="c53030" size={20} />
          </Error>
        )}
      </Container>
    </Label>
  );
};
export default DatePicker;
