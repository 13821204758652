import React, { useEffect, useState, useCallback } from 'react';

import Menu from '../../components/Menu';
import { Chart } from 'react-google-charts';
import { Form } from '@unform/web';

import Select from '../../components/Select';

import { Container, TopBlocks, PieChart, BarChart, LineChart } from './styles';
import api from '../../services/api';

import { useAuth } from '../../hooks/Auth';

interface selectType {
  value: number;
  label: string;
}

const Dashboard: React.FC = () => {
  const [pendingSchedules, setPendingSchedules] = useState();
  const [confirmedSchedules, setConfirmedSchedules] = useState();
  const [canceledSchedules, setCanceledSchedules] = useState();
  const [todaySchedules, setTodaySchedules] = useState();
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [currentOffice, setCurrentOffice] = useState<number[]>([]);
  const [offices, setOffices] = useState<selectType[]>([]);
  const [loadingCharts, setLoadingCharts] = useState(true);
  const [canLoad, setCanLoad] = useState(false);

  const { user } = useAuth();

  // loadOffices
  useEffect(() => {
    api.get('user/offices').then(response => {
      setOffices(
        response.data.map((of: any) => {
          return {
            label: of.name,
            value: of.id,
          };
        }),
      );
      setCanLoad(true);
      setCurrentOffice(
        response.data.map((of: any) => {
          return of.id;
        }),
      );
    });
  }, [user]);

  // loadCharts
  useEffect(() => {
    if (canLoad) {
      setLoadingCharts(true);

      api
        .get(`/numbers-data?offices=${JSON.stringify(currentOffice)}`)
        .then(response => {
          setPendingSchedules(response.data.pending_schedules.total);
          setConfirmedSchedules(response.data.confirmed_schedules.total);
          setCanceledSchedules(response.data.canceled_schedules.total);
          setTodaySchedules(response.data.today_schedules.total);
        });

      api
        .get(`pie-chart-data? offices=${JSON.stringify(currentOffice)}`)
        .then(response => {
          setPieData(response.data.results);
        });

      api
        .get(`column-chart-data?offices=${JSON.stringify(currentOffice)}`)
        .then(response => {
          setBarData(response.data.results);
        });

      api
        .get(`line-chart-data?offices=${JSON.stringify(currentOffice)}`)
        .then(response => {
          setLineData(response.data.results);
        });
    }
  }, [currentOffice]);

  useEffect(() => {
    if (canLoad) {
      setTimeout(() => {
        setLoadingCharts(false);
      }, 500);
    }
  }, [lineData]);

  const handleOffice = useCallback(value => {
    let aux;
    if (value) {
      aux = value.map((item: any) => {
        return item.value;
      });
    } else {
      aux = [];
    }
    setCurrentOffice(aux);
  }, []);

  return (
    <Container>
      <Menu />

      <div className="content">
        <div className="breadcrumb">
          <div>
            <h2>INÍCIO</h2>

            {offices.length > 1 ? (
              <Form onSubmit={() => console.log('search')}>
                <Select
                  name="office"
                  options={offices}
                  onChange={handleOffice}
                  placeholder="Filtrar por unidade"
                  isMulti
                  noOptionsMessage={() =>
                    'Todas as unidades foram selecionadas'
                  }
                  maxHeight={40}
                  fullWidth
                />
              </Form>
            ) : (
              <h2>{`Unidade: ${offices[0]?.label}`}</h2>
            )}
          </div>

          <h2>Bem vindo, {user?.name}</h2>
        </div>

        <TopBlocks>
          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <h2>{todaySchedules}</h2>
                <h4>Agendamentos para hoje</h4>
                <p>A partir de hoje</p>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <h2>{pendingSchedules}</h2>
                <h4>Agendamentos pendentes</h4>
                <p>A partir de hoje</p>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <h2>{confirmedSchedules}</h2>
                <h4>Agendamentos confirmados</h4>
                <p>A partir de hoje</p>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <h2>{canceledSchedules}</h2>
                <h4>Agendamentos cancelados</h4>
                <p>A partir de hoje</p>
              </>
            )}
          </div>
        </TopBlocks>

        <div className="row">
          <PieChart>
            <h3>Agendamentos por tipo </h3>
            {loadingCharts ? (
              <div className="loading">
                <div className="chart" />
              </div>
            ) : (
              <>
                {pieData.length > 0 && (
                  <div className="chart-box">
                    <Chart
                      width={'100%'}
                      height={'300px'}
                      chartType="PieChart"
                      data={pieData}
                      options={{
                        slices: {
                          0: { color: '#476a9e' },
                          1: { color: '#6788ba' },
                          2: { color: '#90CBF9' },
                          3: { color: '#C8E5FC' },
                        },
                        pieHole: 0.4,
                        legend: {
                          position: 'bottom',
                        },
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>
                )}
              </>
            )}
          </PieChart>

          <BarChart>
            <h3>Agendamentos por mês</h3>
            {loadingCharts ? (
              <div className="loading">
                <div className="chart" />
              </div>
            ) : (
              <div className="chart-box">
                <Chart
                  width={'100%'}
                  height={'300px'}
                  chartType="Bar"
                  data={barData}
                  rootProps={{ 'data-testid': '2' }}
                  options={{
                    legend: { position: 'none' },
                    colors: ['#6788ba']
                  }}
                />
              </div>
            )}
          </BarChart>
        </div>

        <LineChart>
          <h3>Agendamentos nos últimos 30 dias</h3>
          {loadingCharts ? (
            <div className="loading">
              <div className="chart" />
            </div>
          ) : (
            <div className="chart-box">
              <Chart
                width={'100%'}
                height={'300px'}
                chartType="Line"
                data={lineData}
                rootProps={{ 'data-testid': '3' }}
                options={{
                  legend: { position: 'none' },
                  colors: ['#6788ba']
                }}
              />
            </div>
          )}
        </LineChart>
      </div>
    </Container>
  );
};

export default Dashboard;
