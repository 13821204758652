import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .content {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding: 0 50px;

    @media (max-width: 769px) {
      padding: 0 15px;
    }

    .breadcrumb {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 60px;
      margin-top: 60px;
      align-items: center;
      flex-wrap: wrap;

      animation: ${appearFromTop} 1s;

      @media (max-width: 769px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 25px 0;
      }

      > div {
        display: flex;
        align-items: center;

        @media (max-width: 769px) {
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #dfdfdf;
          flex-direction: column;
          align-items: normal;
        }
      }

      h2 {
        font-size: 20px;
        color: #9d9d9d;
        font-weight: 400;
        margin-right: 30px;

        & + h2 {
          margin-right: 0;
        }
      }
    }
  }
`;

export const FormContainer = styled.div`
  h2 {
    margin-bottom: 30px;
    color: #9d9d9d;
    font-weight: 500;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .close-btn {
      position: absolute;
      right: 0;
      top: -60px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #6788ba;
    }

    button[type='submit'] {
      border-radius: 5px;
    }

    .intervals {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > div {
        width: calc(100% / 3 - 10px);
      }

      @media (max-width: 768px) {
        flex-direction: column;

        & > div {
          width: 100%;
        }
      }
    }

    hr {
      width: 100%;
      margin: 20px 0;
      color: #9d9d9d;
      border-top: 0.5px solid rgb(157 157 157 / 25%);
    }

    h3 {
      width: 100%;
      margin: 20px 0;
      color: #9d9d9d;
    }

    .tabs {
      border-radius: 10px;

      .header {
        display: flex;

        & > div {
          padding: 10px;
          border: 1px solid #ddd;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;

          &.active, &:hover {
            background: #f1f1f1;
            border-bottom: none;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .content {
        border: 1px solid #ddd;
        background: #f1f1f1;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
      }
    }

    button {
      border-radius: 5px;
      /* max-width: 48%; */

      &.confirm {
        background-color: #40c77c;
        border-color: #40c77c;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#40c77c')};
        }

        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 10px;
        }
      }

      &.cancel {
        background-color: #c53030;
        border-color: #c53030;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#c53030')};
        }

        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    .to-right {
      @media (max-width: 769px) {
        max-width: 100%;
        width: 100%;

        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    button + button {
      margin-right: 15px;
    }

    .attendance-days {
      width: 100%;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;

      label {
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        color: #505050;
        font-size: 18px;
      }

      .days {
        width: 100%;

        .new-day {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;

          @media (max-width: 769px) {
            flex-wrap: wrap;
            padding-top: 20px;

            & + .new-day {
              border-top: 1px solid #ddd;
            }
          }

          > div:nth-child(1),
          > div:nth-child(2) {
            max-width: calc(100% / 4 - 20px);
            width: 100%;

            @media (max-width: 769px) {
              max-width: 100%;
            }
          }

          &.vaccine {
            > div:nth-child(1),
            > div:nth-child(2) {
              max-width: calc(100% / 2 - 45px);
              width: 100%;

              @media (max-width: 769px) {
                max-width: 100%;
              }
            }
          }

          .label {
            max-width: calc(100% / 4 - 20px);
            /* max-width: calc((50% - 15px) / 2 - 10px - 25px); */

            @media (max-width: 769px) {
              max-width: 100%;
            }
          }
        }
      }

      button.delete {
        height: 40px;
        width: 40px;
        border-radius: 5px;
        border: 0;
        background: #ff1329;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }

      button.add-day {
        display: flex;
        float: right;
        width: 100%;
        height: 37px;
        border-radius: 5px;
        border: 1px solid #ddd;
        background: #6788ba;
        font-size: 17px;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        padding-bottom: 7px;
        color: #fff;
        transition: 0.3s;

        @media (max-width: 769px) {
          float: none;
          margin: 20px auto 0;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  .alert-box {
    background: #ddd;
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
`;
