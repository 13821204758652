import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';

interface AppointmentProps {
  isAdmin?: boolean;
}

interface BoxCalendarProps {
  isDisabled?: boolean;
}

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .custom-search-overlay {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
      overflow-y: hidden;
      background: #fff;
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
      border-radius: 20px;
      padding: 30px;

      button {
        background: #6788ba;
        color: #fff;
        border-radius: 10px;
        width: 150px;
        border: none;
        height: 40px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        transition: 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .content {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding: 0 50px;

    @media (max-width: 769px) {
      padding: 0 15px;
    }

    .buttons {
      width: 100%;
      margin-bottom: 10px;
      justify-content: flex-end;
      display: flex;

      button {
        background: #6788ba;
        color: #fff;
        border: none;
        height: 40px;
        border-radius: 10px;
        transition: 0.3s;

        &:hover {
          background: #6788ba;
          color: #fff;
        }
      }

      button {
        width: 250px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .filters {
      align-items: center;
      background: #f1f1f1;
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 10px;
      margin-bottom: 15px;

      &.disabled {
        pointer-events: none;
      }

      .filters-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;

        &.opened {
          padding: 10px 10px 0 10px;
        }

        &.closed {
          padding: 10px 10px;
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .clear-search {
            background: #eb4f44;
            border: none;
            height: 30px;
            transition: all 0.3s;

            &:hover {
              background: #f10;
            }
          }

          > div {
            display: flex;
            align-items: center;

            svg {
              margin-right: 5px;
              color: #6788ba;
              font-size: 20px;
            }

            h2,
            p {
              margin-right: 10px;
            }

            h2 {
              color: #6788ba;
            }
          }

          p {
            color: #939393;

            &:hover {
              cursor: pointer;
            }
          }

          @media (max-width: 768px) {
            h2 {
              font-size: 1rem;
            }
          }
        }

        @media (max-width: 768px) {
          flex-direction: column-reverse;
          align-items: center;

          .buttons {
            width: 100%;
            margin-bottom: 20px;

            button {
              width: 100%;
            }
          }
        }
      }

      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 10px;
        transition: 0.3s;
        /* max-height: 60px; */

        @media (max-width: 768px) {
          max-height: 100%;
        }

        &.opened {
          height: auto;

          .selects,
          .custom-search {
            height: auto;
          }
        }

        &.closed {
          height: 0;
          padding: 0;
          overflow: hidden;

          .selects,
          .custom-search {
            height: 0;
            padding: 0;
            overflow: hidden;
          }
        }

        .selects {
          width: 100%;
          display: flex;
          flex-direction: row;

          form {
            width: 100%;
            justify-content: space-between;
            display: flex;
            flex-direction: row;

            > div {
              max-width: calc((100% / 4) - 5px);

              > div {
                margin-bottom: 10px;
              }
            }

            @media (max-width: 768px) {
              flex-direction: column;

              > div {
                max-width: 100%;
                margin-bottom: 15px;

                > div {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        hr {
          border: 1px solid #dcdcdc85;
          width: 100%;
          margin-bottom: 10px;
        }

        .custom-search {
          width: 100%;

          form {
            width: 100%;
            align-items: center;
            justify-content: flex-start;

            > div {
              width: 100%;

              > div {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .breadcrumb {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 60px;
      margin-top: 60px;
      align-items: center;
      flex-wrap: wrap;

      animation: ${appearFromTop} 1s;

      @media (max-width: 769px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 25px 0;
      }

      > div {
        display: flex;
        align-items: center;

        @media (max-width: 769px) {
          width: 100%;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #dfdfdf;
          flex-direction: column;
          align-items: normal;
        }
      }

      h2 {
        font-size: 20px;
        color: #9d9d9d;
        font-weight: 400;
        margin-right: 30px;

        & + h2 {
          margin-right: 0;
        }
      }
    }

    @media (max-width: 1320px) {
      .filters {
        flex-direction: column;

        form {
          width: 100%;
          justify-content: space-between;

          > div {
            margin-left: 0;
            max-width: calc((70% / 4) - 5px);
            min-width: 0;
          }
        }

        > div {
          width: 100%;

          input {
            width: calc(100% - 106px);
          }
        }
      }
    }

    @media (max-width: 768px) {
      .filters form > div {
        /* max-width: calc(50% - 5px); */
        max-width: 100%;
      }
    }

    .form-field {
      width: 49%;
      position: relative;

      @media (max-width: 768px) {
        width: 100%;
      }

      & > div {
        max-width: 100% !important;
      }

      svg.whatsapp {
        color: #40c77c;
        position: absolute;
        right: 4px;
        top: 26px;
        font-size: 33px;
        cursor: pointer;

        &.inactive {
          color: #ddd !important;
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }

    .sub-actions {
      margin: 25px 0;

      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
      }

      button {
        display: flex;
        align-items: center;
        border: none;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        font-weight: 500;
        background: #6788ba;
        transition: 0.4s;
        box-shadow: 0 3px 6px rgb(0 0 0 / 12%);

        svg {
          font-size: 30px;
          color: #fff;
          cursor: pointer;
          margin-right: 10px;
        }

        &:hover {
          background: #eee;
          color: #6788ba;

          svg {
            color: #6788ba;
          }
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100;

  @media (max-width: 1230px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const Schedules = styled.div`
  flex: 1;
  @media (max-width: 1230px) {
    width: 100%;
  }

  .alert {
    background: #ddd;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    font-weight: 500;
  }

  button {
    &.view-more {
      width: 100%;
      margin-top: 20px;
      height: 50px;
      border-radius: 5px;
      border: none;
      background: #6788ba;
      color: #fff;
      font-weight: 600;
      transition: 0.3s;

      &:hover {
        background: #fff;
        color: #6788ba;
        border: 1px solid #6788ba;
      }
    }
  }

  .action-buttons {
    > div {
      display: flex;
      flex-direction: column;

      button {
        background: #ebeff5;
        border: 1px solid #5b7bab;
        border-radius: 5px;
        padding: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;

        p {
          color: #5b7bab;
        }

        svg {
          color: #5b7bab;
          font-size: 17px;
          margin-left: 5px;
        }

        &:hover {
          background: #5b7bab;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
          transition: 0.3s;

          p {
            color: #fff;
          }

          svg {
            color: #fff;
          }
        }
      }
    }
  }
`;

export const Appointment = styled.div<AppointmentProps>`
  display: flex;
  align-items: center;
  cursor: ${props => (!props.isAdmin ? 'cursor' : 'default')};

  & + div {
    margin-top: 16px;
  }

  > span {
    margin-left: auto;
    display: flex;
    align-items: center;
    line-height: 18px;
    font-weight: 500;
    flex-wrap: wrap;
    max-width: 115px;

    > span {
      width: 100%;
      display: block;
      height: 15px;
    }

    svg {
      margin-right: 8px;
      color: #455f86;
    }
  }

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;

    > div {
      &.details {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 20px;
        border-radius: 10px 10px 0 0;
        margin-left: 24px;
        background: #ebeff5;

        @media (max-width: 1320px) {
          display: block;
        }
        > div {
          &.status {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 24px;
            border-right: 1px solid #b0b8c5;
            width: 15%;

            @media (max-width: 1320px) {
              padding-right: 15px;
              width: 85px;
              float: left;
              height: 85px;
              justify-content: center;
            }
          }

          &:nth-child(2) {
            width: 50%;
          }

          &:nth-child(3) {
            width: 30%;
          }

          span {
            display: block;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #c53030;
          }

          &.Pendente span {
            background: #efff4e;
          }

          &.Confirmado span {
            background: #8bc34a;
          }

          & + div {
            display: flex;
            flex-direction: column;
            margin-left: 24px;

            @media (max-width: 1320px) {
              padding-left: 20px;
            }

            & + div {
              padding-left: 24px;
              border-left: 1px solid #b0b8c5;

              @media (max-width: 1320px) {
                padding-left: 20px;
              }

              @media (max-width: 1320px) {
                &.action-buttons {
                  border: 0;
                  padding-left: 0;
                  width: 100%;
                  margin: 0;
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
    }

    > div {
      &.observations {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0 0 10px 10px;
        margin-left: 24px;
        background: #ebeff5;
        padding: 5px 20px 20px 20px;
      }
    }

    @media (max-width: 540px) {
      flex-direction: column;

      & + div {
        margin-top: 30px;
      }

      > span {
        margin: 0 0 10px;
      }

      > div {
        display: block;
        width: 100%;
        margin: 0;
      }
    }
  }



`;

export const BoxCalendar = styled.div`
  width: 380px;
  max-width: 100%;
  margin-left: 30px;

  @media (max-width: 1230px) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .DayPicker {
    background: #5b7bab;
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
    width: calc(100% - 32px);
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #455f86;
    border-radius: 10px;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#455f86')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #b4b4b4 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #fff !important;
    border-radius: 10px;
    color: #232129 !important;
  }

  .DayPicker-Weekday {
    color: #fff;
  }

  .DayPicker-Caption {
    color: #fff;
  }
`;

export const FormContainer = styled.div`
  h2 {
    margin-bottom: 30px;
    color: #9d9d9d;
    font-weight: 500;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    hr {
      width: 100%;
      margin: 20px 0;
      border-top: 0.5px solid rgb(157 157 157 / 25%);
    }

    h3 {
      width: 100%;
      margin-bottom: 20px;
      color: #9d9d9d;
    }

    .close-btn {
      position: absolute;
      right: 0;
      top: -60px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #6788ba;
    }

    .office-addres {
      width: 100%;
      padding: 15px 0;
      margin: 10px 0 30px;
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      color: #333;

      h3 {
        font-size: 18px;
        margin-bottom: 6px;
      }
    }

    button {
      border-radius: 5px;

      &.confirm {
        background-color: #40c77c;
        border-color: #40c77c;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#40c77c')};
        }
      }

      &.cancel {
        background-color: #c53030;
        border-color: #c53030;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#c53030')};
        }
      }
    }

    button + button {
      margin-right: 15px;
    }
  }

  .alert-box {
    background: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
  }
`;
