import React from 'react';
import { Router, Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';

import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import Offices from '../pages/Offices';
import Calendar from '../pages/Calendar';
import ResetPassword from '../pages/ResetPassword';
import history from '../services/history';
import SchedulesPdf from '../pages/SchedulesPdf';
import Vaccines from '../pages/Vaccines';
import Exams from '../pages/Exams';

const Routes: React.FC = () => (
  // <Router history={history}>
  <Switch>
    <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />

    <Route
      path={`${process.env.PUBLIC_URL}/`}
      component={Dashboard}
      exact
      isPrivate
    />
    <Route
      path={`${process.env.PUBLIC_URL}/usuarios`}
      component={Users}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/unidades`}
      component={Offices}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/agenda`}
      component={Calendar}
      isPrivate
    />
    <Route
      path={`${process.env.PUBLIC_URL}/recuperar-senha`}
      component={ResetPassword}
    />
    <Route
      path={`${process.env.PUBLIC_URL}/agendamentos/pdf`}
      component={SchedulesPdf}
      isPrivate
    />
    <Route
      path={`${process.env.PUBLIC_URL}/vacinas`}
      component={Vaccines}
      isPrivate
    />
    <Route
      path={`${process.env.PUBLIC_URL}/exames`}
      component={Exams}
      isPrivate
    />
  </Switch>
  // </Router>
);

export default Routes;
