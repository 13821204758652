import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useToast } from '../../hooks/Toast';

import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';

import { Item } from './index';

import { FormContainer } from './styles';
import api from '../../services/api';
import { FiXCircle, FiDelete } from 'react-icons/fi';

import cep from 'cep-promise';

interface FormData {
  item: Item | null;
  closeForm(): void;
}

const FormModal: React.FC<FormData> = ({ item, closeForm }) => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data: Item) => {
    try {
      const formData = {
        name: data.name,
        description: data.description,
      };

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        description: Yup.string().nullable(),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      let response;
      if (item) {
        response = await api.put(`vaccines/${item.id}`, formData);
      } else {
        response = await api.post('vaccines', formData);
      }
      addToast({
        type: 'success',
        title: `Cadastro ${item ? 'atualizado' : 'realizado'}`,
        description: response.data.message,
      });

      closeForm();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      } else {
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            'Ocorreu um erro ao salvar, verifique os dados e tente novamente',
        });
      }
    }
  }, []);

  return (
    <FormContainer>
      <h2>{item ? 'Editar' : 'Nova'} vacina</h2>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={item ? item : undefined}
      >
        <button type="button" onClick={() => closeForm()} className="close-btn">
          <FiXCircle size={30} />
        </button>
        <Input name="name" label="Nome" fullWidth />

        <Textarea name="description" label="Descrição" fullWidth />

        <div className="to-right">
          <Button type="submit">Salvar</Button>
        </div>
      </Form>
    </FormContainer>
  );
};

export default FormModal;
