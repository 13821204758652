import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FiHome,
  FiUsers,
  FiMap,
  FiCalendar,
  FiLogOut,
  FiMenu,
  FiX,
} from 'react-icons/fi';
import { GiLoveInjection } from 'react-icons/gi';

import { useAuth } from '../../hooks/Auth';

import { Container } from './styles';

import Logo from '../../assets/logo.jpeg';
import { FaCaretDown, FaCaretUp, FaUsers } from 'react-icons/fa';

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuCliente, setMenuCliente] = useState(false);

  const { signOut, user } = useAuth();

  const handleMenuClient = useCallback(
    event => {
      event.preventDefault();
      setMenuCliente(!menuCliente);
    },
    [menuCliente],
  );

  return (
    <Container>
      <div className="fixed">
        <div className="logo">
          <img src={Logo} alt="Vacinas São Bento" />
        </div>
        <div className="menu">
          <button
            type="button"
            className="toogle-menu"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {!menuOpen ? <FiMenu size={20} /> : <FiX />}
          </button>
          <ul className={menuOpen ? `open` : ``}>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/`}>
                <FiHome size={22} />
                Início
              </Link>
            </li>
            {user.role_id === 1 && (
              <>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/usuarios`}>
                    <FiUsers size={22} />
                    Usuários
                  </Link>
                </li>

                <li>
                  <Link to={`${process.env.PUBLIC_URL}/unidades`}>
                    <FiMap size={22} />
                    Unidades
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link to={`${process.env.PUBLIC_URL}/agenda`}>
                <FiCalendar size={22} />
                Agenda
              </Link>
            </li>
            <li className="has-submenu">
              <a onClick={handleMenuClient}>
                <GiLoveInjection size={20} />
                Produtos
                {!menuCliente ? (
                  <FaCaretDown size={16} className="menu-toogle" />
                ) : (
                  <FaCaretUp size={16} className="menu-toogle" />
                )}
              </a>
              <ul className={menuCliente ? `submenu open` : `submenu`}>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/vacinas`}>
                    Vacinas
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/exames`}>
                    Exames
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to={`${process.env.PUBLIC_URL}/vacinas`}>
                <GiLoveInjection size={22} />
                Vacinas
              </Link>
            </li> */}

            <li>
              <a href="/" onClick={() => signOut()}>
                <FiLogOut size={22} />
                Sair
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Menu;
