import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  min-width: 170px;

  @media (max-width: 1024px) {
    height: 60px;
  }

  @media print {
    display: none;
  }

  .fixed {
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 170px;
    animation: ${appearFromLeft} 1s;

    @media (max-width: 1024px) {
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      background: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    .logo {
      height: 20%;
      display: flex;
      place-content: center;
      place-items: center;
      background: #fff;
      width: 100%;

      @media (max-width: 1024px) {
        height: 100%;
        width: auto;

        img {
          height: 60px;
          object-fit: contain;
          padding: 5px 0;
        }
      }

      img {
        max-width: 90%;
      }
    }

    .menu {
      height: 80%;
      background: linear-gradient(#3d5983, #6788ba, #6788ba);

      .toogle-menu {
        display: none;
        margin: 8px 0;
        background: transparent;
        border: 2px solid #6788ba;
        width: 40px;
        align-items: center;
        justify-content: center;
        color: #6788ba;
        border-radius: 3px;
        height: 32px;
      }

      ul {
        padding: 30px 20px 0;
        list-style: none;

        li {
          width: 100%;

          a {
            width: 100%;
            display: flex;
            color: #fff;
            text-decoration: none;
            line-height: 25px;
            font-size: 16px;
            padding: 10px 0;
            cursor: pointer;
            position: relative;
            align-items: center;

            svg {
              float: left;
              line-height: 23px;
              margin-right: 10px;
              margin-top: 2px;

              &.menu-toogle {
                position: absolute;
                right: 0;
                margin-right: 0;
                margin-top: 5px;
                z-index: 11;

                @media (max-width: 769px) {
                  right: 15px;
                }
              }
            }
          }
        }

        ul.submenu {
          width: calc(100% + 40px);
          margin-left: -20px;
          background: rgb(255 255 255 / 0.1);
          padding: 0 20px;
          transform: scaleY(0);
          overflow: hidden;
          transition: transform 0.4s;
          transform-origin: top;
          height: 0;

          &.open {
            transform: scaleY(1);
            padding: 0 20px;
            height: auto;
          }
        }
      }

      /* ul {
        padding: 30px 20px 0;
        list-style: none;

        li {
          width: 100%;
          margin-bottom: 25px;

          a {
            width: 100%;
            display: block;
            color: #fff;
            text-decoration: none;
            line-height: 25px;
            font-size: 16px;

            svg {
              float: left;
              line-height: 23px;
              margin-right: 10px;
            }
          }
        }
      } */

      @media (max-width: 1024px) {
        background: transparent;

        ul {
          display: flex;
          padding: 12px 0;

          li {
            margin-bottom: 0;

            & + li {
              margin-left: 20px;
            }

            a {
              display: flex;
              color: #3d5983;

              svg {
                margin-right: 5px;
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        .toogle-menu {
          display: flex;
          z-index: 11;
        }

        ul {
          position: absolute;
          right: 0;
          flex-direction: column;
          background: #fff;
          margin-top: 6px;
          width: 100%;
          box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.16);
          padding: 0;
          transform: scaleY(0);
          transition: transform 0.4s;
          overflow: hidden;
          transform-origin: top;
          max-height: calc(100vh - 60px);
          overflow: auto;

          &.open {
            transform: scaleX(1);
          }

          li {
            padding: 0;
            margin: 0 !important;
            border-top: 1px solid #ddd;

            a {
              padding: 12px 15px;
              font-weight: 500;
            }
          }

          ul.submenu.open {
            position: relative;
            margin-left: -20px;
            padding-left: 20px;
            width: calc(100% + 40px);
            box-shadow: none;
          }
        }
      }
      /* @media (max-width: 768px) {
        .toogle-menu {
          display: flex;
        }

        ul {
          position: absolute;
          right: 0;
          flex-direction: column;
          background: #fff;
          margin-top: 6px;
          width: 100%;
          box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.16);
          padding: 0;
          max-height: 0;
          transition: max-height 0.4s;
          overflow: hidden;
          &.open {
            max-height: 260px;
          }

          li {
            padding: 13px 0 13px 20px;
            margin: 0 !important;
            border-top: 1px solid #ddd;
          }
        }
      } */
    }
  }
`;
