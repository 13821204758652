import React, { useRef, useEffect } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error, Label } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  fullWidth?: boolean;
  label?: string;
}

const Select: React.FC<Props> = ({
  name,
  fullWidth = false,
  label,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      clearValue: (ref, value) => {
        ref.select.setValue(null);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);
  return (
    <Label fullWidth={fullWidth} className="label">
      {!!label && <strong>{label}</strong>}
      <Container isErrored={!!error}>
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="c53030" size={20} />
          </Error>
        )}
      </Container>
    </Label>
  );
};

export default Select;
