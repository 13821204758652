import React, { useEffect, useState, useCallback } from 'react';

import Menu from '../../components/Menu';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';

import Form from './form';

import { Container } from './styles';
import api from '../../services/api';

import { useAuth } from '../../hooks/Auth';
import { BiEdit } from 'react-icons/bi';

import { GiLoveInjection } from 'react-icons/gi';
import FormVaccines from './vaccines';
import FormExams from './exams';
import { useToast } from '../../hooks/Toast';
import { HiOutlineClipboardList } from 'react-icons/hi';

export interface Office {
  name: string;
  id: number;
  phone: string;
  address: string;
  city: string;
  number: string;
  active: boolean;
  homecare_interval?: any;
  clinic_interval?: any;
  drivethru_interval?: any;
  covid_interval?: any;
  working_days: {
    id: number;
    pivot: {
      working_day_id: number;
      hour_start: string;
      hour_end: string;
      modality_id: number;
      type?: string;
    };
  }[];
  vaccines: {
    id: number;
    name: string;
    pivot: {
      vaccine_id: number;
      office_id: number;
      available: number;
    };
    label?: string;
    value?: number;
  }[];
  exams: {
    id: number;
    name: string;
    pivot: {
      exam_id: number;
      office_id: number;
      available: number;
    };
    label?: string;
    value?: number;
  }[];
}

const Offices: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormVaccines, setShowFormVaccines] = useState(false);
  const [showFormExams, setShowFormExams] = useState(false);
  const [currentOffice, setCurrentOffice] = useState<Office | null>(null);
  const [units, setUnits] = useState<Office[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { user } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('offices', { params: { search: filteredAttr, page: currentPage } })
      .then(response => {
        setUnits(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      }).catch(err => {
        setUnits([]);
        setLoadingData(false);
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description: err.message,
        });
      });
  }, [showForm, showFormVaccines, showFormExams, filteredAttr, currentPage]);

  const handleEdit = useCallback((item: Office) => {
    setCurrentOffice(item);
    setShowForm(true);
  }, []);

  const handleOpenFormVaccines = useCallback((item: Office) => {
    setCurrentOffice(item);
    setShowFormVaccines(true);
  }, []);

  const handleOpenFormExams = useCallback((item: Office) => {
    setCurrentOffice(item);
    setShowFormExams(true);
  }, []);

  const closeForm = useCallback(() => {
    setCurrentOffice(null);
    setShowForm(false);
    setShowFormVaccines(false);
    setShowFormExams(false);
  }, []);

  return (
    <Container>
      <Menu />

      <div className="content">
        <div className="breadcrumb">
          <h2>INÍCIO / UNIDADES</h2>
          <h2>Bem vindo, {user.name}</h2>
        </div>
        <Table>
          {!showForm && !showFormVaccines && !showFormExams && (
            <>
              <div className="filters to-right">
                <input
                  type="text"
                  name="search"
                  placeholder="Filtrar unidades"
                  onChange={event => setFilteredAttr(event.target.value)}
                  value={filteredAttr}
                  className="filter-input"
                />
                <button
                  type="button"
                  className="new-btn"
                  onClick={() => setShowForm(true)}
                >
                  Novo
                </button>
              </div>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <td>Unidade</td>
                      <td>Telefone</td>
                      <td>Cidade</td>
                      <td>Endereço</td>
                      <td>Status</td>
                      <td>Açõe(s)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingData ? (
                      <tr className="loading">
                        <td colSpan={6}>
                          <p className="alert">Carregando unidades...</p>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {units.length > 0 ? (
                          <>
                            {units.map(item => (
                              <tr key={item.id!.toString()}>
                                <td>{item.name}</td>
                                <td>
                                  {item.phone ? item.phone : 'Não informado'}
                                </td>
                                <td>{item.city}</td>
                                <td>
                                  {item.address}, {item.number}
                                </td>
                                <td>{item.active ? 'Ativo' : 'Desativado'}</td>
                                <td>
                                  <button
                                    type="button"
                                    title="Exames"
                                    onClick={() => handleOpenFormExams(item)}
                                  >
                                    <HiOutlineClipboardList size={22} />
                                  </button>
                                  <button
                                    type="button"
                                    title="Vacinas"
                                    onClick={() => handleOpenFormVaccines(item)}
                                  >
                                    <GiLoveInjection size={22} />
                                  </button>
                                  <button
                                    type="button"
                                    title="Editar"
                                    onClick={() => handleEdit(item)}
                                  >
                                    <BiEdit size={22} />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <p className="alert">
                                Nenhum resultado encontrado
                              </p>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <Pagination
                  pages={totalPages}
                  currentPage={currentPage}
                  setPage={p => setCurrentPage(p)}
                />
              </div>
            </>
          )}

          {showForm && (
            <Form unit={currentOffice} closeForm={() => closeForm()} />
          )}

          {showFormVaccines && (
            <FormVaccines unit={currentOffice} closeForm={() => closeForm()} />
          )}

          {showFormExams && (
            <FormExams unit={currentOffice} closeForm={() => closeForm()} />
          )}
        </Table>
      </div>
    </Container>
  );
};

export default Offices;
